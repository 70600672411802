import type { Action } from "svelte/action";
import { sheetScope } from "./utils";

const logger = /*           */ sheetScope.scope("focus");
const sheetFocusTrapLog = /*           */ logger.scope("trap");
const sheetCaptureFocusLog = /*           */ logger.scope("capture");

type SheetFocusTrapParams = {
  hasBackButton: boolean;
  backButtonElement: HTMLElement | undefined;
  closeButtonElement: HTMLElement | undefined;
  sheetElementVisible: boolean;
};

function trap(opts: SheetFocusTrapParams): void {
  if (!opts.sheetElementVisible) {
    if (import.meta.env.DEV) sheetFocusTrapLog.info("sheet inactive, skip focus trap");
    return;
  }

  if (opts.hasBackButton) {
    if (import.meta.env.DEV) sheetFocusTrapLog.info("back button element", opts.backButtonElement);
    opts.backButtonElement?.focus();
    return;
  }

  if (import.meta.env.DEV) sheetFocusTrapLog.info("close button element", opts.closeButtonElement);
  /*                        */
  opts.closeButtonElement?.focus();
}

/**
 *
 *
 *
 *
 *
 */
export const sheetFocusTrap: Action<HTMLElement, SheetFocusTrapParams> = function sheetFocusTrap(
  element,
  options: SheetFocusTrapParams,
) {
  /*                                                       */
  if (import.meta.env.STORYBOOK && window.parent !== window.self) {
    if (import.meta.env.DEV) sheetFocusTrapLog.info("Disable focus trap in Storybook context");
    return {};
  }

  let opts = $state(options);

  $effect(() => {
    const handleTrap = (): void => trap(opts);
    element.addEventListener("transitionend", handleTrap);

    return () => {
      if (import.meta.env.DEV) sheetFocusTrapLog.info("remove event listener");
      element.removeEventListener("transitionend", handleTrap);
    };
  });

  return {
    update(parameter) {
      opts = parameter;
    },
  };
};

/**
 *
 *
 *
 *
 *
 *
 */
export const captureFocus: Action<HTMLDivElement, { hostElementVisible: boolean }> = (
  element,
  options: { hostElementVisible: boolean },
) => {
  /*                                                                */
  /*                                                                                               */
  /*           */
  /**/

  let opts = $state(options);

  $effect(() => {
    if (opts.hostElementVisible) {
      if (import.meta.env.DEV) sheetCaptureFocusLog.info("focus sheet element");
      element.focus();
    }
  });

  return {
    update(parameter) {
      opts = parameter;
    },
  };
};
