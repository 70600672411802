export function getActions(sheet: HTMLOcSheetV1Element | null): HTMLElement | null {
  return sheet?.querySelector("[slot=actions]") ?? null;
}

/**
 *
 *
 *
 *
 *
 */
export function getSheetElement(sheetID: string): HTMLOcSheetV1Element | null {
  return sheetID ? document.querySelector(`oc-sheet-v1[id="${sheetID}"]`) : null;
}

/**
 *
 *
 *
 */
export function getHeader(sheet: HTMLOcSheetV1Element | null): HTMLElement | null {
  return sheet?.shadowRoot!.querySelector(".sheet__header") ?? null;
}

export function isElement(value: ChildNode): value is Element {
  return value.nodeType === 1;
}

/**
 *
 *
 *
 *
 */
export function getContent(sheet: HTMLOcSheetV1Element | null): HTMLElement | null {
  const container = document.createElement("div");

  sheet?.childNodes.forEach((child) => {
    /*                                        */
    if (!isElement(child) || !child.getAttributeNode("slot")) {
      /*                                                      */
      /*                                                                */
      container.append(child.cloneNode(true));
    }
  });

  return container.children.length ? container : null;
}
