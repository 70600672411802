<svelte:options
  customElement={{
    tag: "oc-form-group-v1",
    /*                       */
    extend: window.__components.extend(),
    props: {
      orientation: { type: "String" },
      gap: { type: "String" },
      flexBehavior: { type: "String", attribute: "flex-behavior" },
      ocAriaLabel: { type: "String", attribute: "oc-aria-label" },
      validationMessage: { type: "String", attribute: "validation-message" },
      hint: { type: "String" },
    },
  }}
/>

<script lang="ts">
  import type { Props } from "./FormGroupV1.types";
  import { isControllableElement } from "./isControllableElement";

  let {
    orientation = undefined,
    flexBehavior = undefined,
    ocAriaLabel = undefined,
    validationMessage = undefined,
    hint = undefined,
    gap = undefined,
  }: Props = $props();

  const Host = $host();

  let ariaDescribedBy = $derived(
    [validationMessage && "validation-message", hint && "hint"].filter(Boolean).join(" ") ||
      undefined,
  );

  const ocElements = Array.from(Host.querySelectorAll("*")).filter(isControllableElement);

  $effect(() => {
    ocElements.forEach((el) => {
      el.validationMessage = validationMessage;
      el.hint = hint;
      el.hideDetails = true;
    });
  });

  const observer = new MutationObserver(async (c) => {
    c.forEach((mutation) => {
      mutation.addedNodes.forEach((node) => {
        if (isControllableElement(node)) {
          ocElements.push(node);
          node.validationMessage = validationMessage;
          node.hint = hint;
          node.hideDetails = true;
        }
      });
    });
  });

  observer.observe(Host, {
    childList: true,
    subtree: true,
  });
</script>

<div
  role="group"
  aria-label={ocAriaLabel}
  aria-describedby={ariaDescribedBy}
  class="form-group"
  class:form-group--orientation-vertical={orientation === "vertical"}
  class:form-group--orientation-horizontal={orientation === "horizontal"}
  class:form-group--shrink-items={flexBehavior === "shrink"}
  class:form-group--grow-items={flexBehavior === "grow"}
  style:--gap={gap}
>
  <slot></slot>
</div>

{#if validationMessage}
  <div class="validation-message" id="validation-message">{validationMessage}</div>
{/if}

{#if hint}
  <div class="hint" id="hint">{hint}</div>
{/if}

<style lang="scss" global>
  @use "@otto-ec/design-tokens/component" as tokens;
  @use "@otto-ec/otto-components-utils/scss/mixins";

  :host {
    /*        */
    --gap: unset;
    --column-gap: unset;
    --row-gap: unset;

    display: flex;
    flex-direction: column;
    gap: tokens.$oc-component-form-hint-gap-y;
  }

  .form-group {
    max-width: 100%;
  }

  .form-group--shrink-items {
    ::slotted(*) {
      flex: 0 1 auto;
    }
  }

  .form-group--grow-items {
    ::slotted(*) {
      max-width: 100%;
      min-width: fit-content;
      flex: 1 0 auto;
    }
  }

  .form-group--orientation-vertical,
  .form-group--orientation-horizontal {
    display: flex;
    flex-wrap: wrap;
    column-gap: var(--column-gap, var(--gap, tokens.$oc-component-form-group-gap-x));
    row-gap: var(--row-gap, var(--gap, tokens.$oc-component-form-group-gap-y));
  }

  .form-group--orientation-vertical {
    flex-direction: column;
  }

  .validation-message {
    font: tokens.$oc-component-form-validation-message-font;
    color: tokens.$oc-component-form-error-validation-message-color;
    word-break: break-word;
  }

  .hint {
    font: tokens.$oc-component-form-hint-font;
    color: tokens.$oc-component-form-default-hint-color;
    word-break: break-word;
  }
</style>
