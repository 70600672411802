import { derived, get, writable, type Readable, type Writable } from "svelte/store";
import { CLOSE_TYPES, CLOSED_WITH_CURTAIN_CLICK, CLOSED_WITH_X } from "./events.svelte";
import { close, lastActiveDomElement, noTransition } from "./sheetHistory";
import { sheetScope } from "./utils";

const log = /*           */ sheetScope.scope("visibility");

const OPEN_CLOSE_ANIMATION_DURATION = 210;

export function onBackdropClick(): void {
  close(CLOSE_TYPES[CLOSED_WITH_CURTAIN_CLICK]);
}

export function onCloseButtonClick(e: MouseEvent): void {
  /*                                                           */
  /*                                                                                 */
  const activeElement = get(lastActiveDomElement);

  /*                                                                  */
  /*                                                             */
  if (e.detail === 0 && activeElement) {
    activeElement.focus();
  }

  close(CLOSE_TYPES[CLOSED_WITH_X]);
}

function showSheet(
  hostElementVisible: Writable<boolean>,
  sheetElementVisible: Writable<boolean>,
  sheetElementFullyOpened: Writable<boolean>,
  noTransitionSet: boolean,
  animationTimeout: number | undefined,
): void {
  /*                                   */
  clearTimeout(animationTimeout);

  /*                                                                              */
  hostElementVisible.set(true);

  /*                                               */
  if (noTransitionSet) {
    sheetElementVisible.set(true);
    sheetElementFullyOpened.set(true);
    return;
  }

  /*                                     */
  /*                                                                            */
  window.setTimeout(() => sheetElementVisible.set(true), 0);
  /*                   */
  window.setTimeout(() => sheetElementFullyOpened.set(true), OPEN_CLOSE_ANIMATION_DURATION);
}

function hideSheet(
  hostElementVisible: Writable<boolean>,
  sheetElementVisible: Writable<boolean>,
  sheetElementFullyOpened: Writable<boolean>,
  noTransitionSet: boolean,
): number | undefined {
  /*                      */
  sheetElementFullyOpened.set(false);
  sheetElementVisible.set(false);

  /*                                                                                        */
  if (noTransitionSet) {
    hostElementVisible.set(false);
    return undefined;
  }

  /*                                                */
  return window.setTimeout(() => hostElementVisible.set(false), OPEN_CLOSE_ANIMATION_DURATION);
}

/**
 *
 *
 *
 *
 *
 */
/*                                                                        */
export function useSheetVisibility(host: HTMLOcSheetV1Element, isActiveSheet: Readable<boolean>) {
  if (import.meta.env.DEV) log.debug("initialize useSheetVisibility", host);

  /**
 *
 *
 */
  const hostElementVisible = writable(false);
  /*                                                              */
  hostElementVisible.subscribe((isOpen) => {
    host.style.display = isOpen ? "" : "none";
    if (import.meta.env.DEV)
      log.debug("hostElementVisible:", host.id, "isOpen:", isOpen, "display:", host.style.display);
  });

  /**
 *
 *
 *
 */
  const sheetElementVisible = writable(false);

  /**
 *
 */
  const sheetElementFullyOpened = writable(false);

  let animationTimeout: number | undefined;

  const activationData = derived(
    [isActiveSheet, hostElementVisible, noTransition] as const,
    (_) => _,
  );
  activationData.subscribe(([isActive, hostVisible, noTrans]) => {
    if (isActive) {
      /*                                                         */
      /*                                       */
      showSheet(
        hostElementVisible,
        sheetElementVisible,
        sheetElementFullyOpened,
        noTrans,
        animationTimeout,
      );
    }

    if (!isActive && hostVisible) {
      /*                                                                           */

      /*                                                         */
      animationTimeout = hideSheet(
        hostElementVisible,
        sheetElementVisible,
        sheetElementFullyOpened,
        noTrans,
      );
    }
  });
  if (import.meta.env.DEV)
    activationData.subscribe(([a, v, t]) =>
      log.info(
        "instance activation changed",
        host.id,
        "active:",
        a,
        "visible:",
        v,
        "noTransition:",
        t,
      ),
    );

  /**
 *
 *
 *
 *
 *
 *
 *
 */
  function computeAriaLabelByHeadlineSlot(
    ocAriaLabel: string | undefined,
    headline: string | undefined,
  ): string | undefined {
    /*                                                                                 */
    return ocAriaLabel || host.querySelector(`[slot="headline"]`)?.textContent || headline;
  }

  return {
    /*                                                 */
    hostElementVisible,
    /*                                                            */
    sheetElementVisible,

    sheetElementFullyOpened,

    computeAriaLabelByHeadlineSlot,
  };
}
