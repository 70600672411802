<svelte:options
  customElement={{
    tag: "oc-carousel-v1",
    shadow: "none",
    /*                                            */
    extend: window.__components.extend({ delegateFocus: true }),
    props: {
      arrowButtonPosition: { type: "String", attribute: "arrow-button-position" },
      ocAriaLabel: { type: "String", attribute: "oc-aria-label" },
      hideArrowButtons: { type: "Boolean", attribute: "hide-arrow-buttons" },
      hidePaginationDots: { type: "Boolean", attribute: "hide-pagination-dots" },
    },
  }}
/>

<script lang="ts">
  import { onMount } from "svelte";
  import { useEventDispatcher } from "@otto-ec/otto-components-utils/use/event-dispatcher";
  import { refireScrollEvent } from "../../../common/actions/refireScrollEvent";
  import { ArrowButtonV1 } from "../../../common/components/ArrowButton";
  import PaginationDotsV1 from "../../../common/components/PaginationDotsV1/PaginationDotsV1.svelte";

  import type { Events, Props } from "./CarouselV1.types";

  type CarouselItemHTMLElement = {
    isVisible?: boolean;
  } & HTMLElement;

  let {
    ocAriaLabel,
    arrowButtonPosition,
    hideArrowButtons,
    hidePaginationDots,
    visibleItem = undefined,
  }: Props = $props();

  const Host = $host<HTMLElement>();

  const dispatch = useEventDispatcher<Events>(Host);

  let slideCount = $state(Host.childElementCount);
  let carouselStage = $state<HTMLElement>();
  let stageScrollSource: "arrows" | "pagination-dots" | "move-method" | "swipe" = "swipe";
  let stageScrolling = false;
  let prevCurrentItemIndex = 0;
  let currentItemIndex = $state(0);
  let items = $state(Array.from(Host.children) as CarouselItemHTMLElement[]);

  /*                                                        */
  let hideLeftArrow = $state(true);
  let hideRightArrow = $state(true);

  let intersectingTimer = -1;

  let visibleItemIndex = -1;

  /*                                             */
  const intersectingFinished = () => {
    if (currentItemIndex === prevCurrentItemIndex) return;

    stageScrolling = false;

    dispatch(
      "oc-stage-scroll",
      {
        isScrolling: stageScrolling,
        source: stageScrollSource,
      },
      { cancelable: false },
    );

    if (visibleItem) {
      dispatch(
        "oc-item-visibility-changed",
        {
          visibleItem: {
            element: visibleItem,
            index: visibleItemIndex,
          },
          scrollDirection: currentItemIndex > prevCurrentItemIndex ? "right" : "left",
          stageIsScrolling: stageScrolling,
          scrollSource: stageScrollSource,
        },
        { cancelable: false },
      );
    }

    prevCurrentItemIndex = currentItemIndex;
    stageScrollSource = "swipe";
  };

  const carouselIntersecting = () => {
    clearTimeout(intersectingTimer);

    intersectingTimer = window.setTimeout(intersectingFinished, 500);
  };

  onMount(() => {
    carouselStage?.addEventListener("scroll", carouselIntersecting, { passive: true });

    const intersectionObserverOptions: IntersectionObserverInit = {
      root: carouselStage,
      threshold: 0.5,
    };

    let lastIntersectingItem: CarouselItemHTMLElement | null = null;

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        const target = entry.target as CarouselItemHTMLElement;
        target.isVisible = entry.isIntersecting;
        if (entry.isIntersecting) {
          stageScrolling = true;
          lastIntersectingItem = target;
        }
      });
      visibleItemIndex = items.findIndex((item) => item.isVisible);
      visibleItem = items[visibleItemIndex];

      currentItemIndex = items.findIndex((i) => i.isVisible);
      if (currentItemIndex === -1) {
        /*                                                            */
        currentItemIndex = items.findIndex((i) => i === lastIntersectingItem);
      }

      hideLeftArrow = currentItemIndex === 0;
      hideRightArrow = currentItemIndex === slideCount - 1;

      if (currentItemIndex !== prevCurrentItemIndex) {
        dispatch(
          "oc-stage-scroll",
          {
            isScrolling: stageScrolling,
            source: stageScrollSource,
          },
          { cancelable: false },
        );

        if (visibleItem) {
          dispatch(
            "oc-item-visibility-changed",
            {
              visibleItem: {
                element: visibleItem,
                index: visibleItemIndex,
              },
              scrollDirection: currentItemIndex > prevCurrentItemIndex ? "right" : "left",
              stageIsScrolling: stageScrolling,
              scrollSource: stageScrollSource,
            },
            { cancelable: false },
          );
        }
      }
    }, intersectionObserverOptions);

    /*                 */
    Array.from(Host.children).forEach((child) => {
      observer.observe(child);
    });

    slideCount = Host.childElementCount;
    new MutationObserver((mutations) => {
      mutations.forEach(({ removedNodes, addedNodes }) => {
        addedNodes.forEach((node) => {
          if (node instanceof HTMLElement) observer.observe(node);
        });
        removedNodes.forEach((node) => {
          if (node instanceof HTMLElement) observer.unobserve(node);
        });

        items = Array.from(Host.children) as CarouselItemHTMLElement[];
      });
      slideCount = Host.childElementCount;
    }).observe(Host, { childList: true });

    return () => {
      observer.disconnect();
    };
  });

  export const moveTo = (
    index: number,
    options?: { source?: "arrows" | "pagination-dots"; reducedMotion?: boolean },
  ) => {
    if (carouselStage === undefined) {
      return;
    }

    if (index >= 0 && index <= slideCount - 1) {
      const item = Host.children.item(index);
      if (item instanceof HTMLElement) {
        options?.source !== undefined
          ? (stageScrollSource = options.source)
          : (stageScrollSource = "move-method");

        if (
          options?.reducedMotion ||
          window.matchMedia("(prefers-reduced-motion: reduce)").matches
        ) {
          carouselStage.style.scrollBehavior = "auto";
        } else {
          carouselStage.style.scrollBehavior = "smooth";
        }
        carouselStage.scrollLeft = item?.offsetLeft;
      }
    }
  };
</script>

<div class="carousel" style:--arrow-button-position={arrowButtonPosition}>
  <section
    class="carousel__stage"
    bind:this={carouselStage}
    use:refireScrollEvent={Host}
    aria-label={ocAriaLabel}
    data-oc-floating-focus-v1-target
  >
    <slot />
  </section>
  {#if !hideArrowButtons}
    <ArrowButtonV1
      hidden={hideLeftArrow}
      direction="left"
      onclick={() => moveTo(currentItemIndex - 1, { source: "arrows" })}
    />
    <ArrowButtonV1
      hidden={hideRightArrow}
      direction="right"
      onclick={() => moveTo(currentItemIndex + 1, { source: "arrows" })}
    />
  {/if}
</div>

{#if !hidePaginationDots}
  <PaginationDotsV1
    amountDots={slideCount}
    slides={items}
    selectedDot={currentItemIndex}
    {moveTo}
    isCarousel
  />
{/if}

<style lang="scss" global>
  @use "@otto-ec/otto-components-utils/scss/mixins";
  @use "@otto-ec/design-tokens/component" as tokens;
  @use "../../floating-focus/v1/FloatingFocusV1.global";

  /*                                         */
  ::slotted(*) {
    scroll-snap-align: center;
    scroll-snap-stop: always;
    flex: 0 0 100%;
  }

  :host {
    /*        */
    --arrow-button-position: 50%;

    @include mixins.no-tap-highlight();
    display: block;
  }

  .carousel {
    position: relative;
    overflow: hidden;

    &__stage {
      display: flex;
      gap: tokens.$oc-component-carousel-stage-gap-x;
      overflow-x: auto;
      scrollbar-width: none; /*     */
      scroll-snap-type: x mandatory;

      /*                                  */
      margin: -4px;
      padding: 4px;

      /*                                    */
      @supports (scroll-snap-type: x mandatory) {
        scroll-snap-type: x mandatory;
      }

      /*                                    */
      @supports (scroll-behavior: smooth) {
        scroll-behavior: smooth;
      }

      /*                                    */
      @supports (scroll-behavior: smooth) and (scroll-snap-type: x mandatory) {
        scroll-behavior: smooth;
        scroll-snap-type: x mandatory;
      }

      /*                                            */
      @media (prefers-reduced-motion) {
        scroll-behavior: auto;
      }

      &::-webkit-scrollbar {
        display: none; /*                */
      }
    }
  }
</style>
