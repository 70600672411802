/*                                  */
/**
 *
 */
export const logoMap = {
  "pl_logo_app-store": {
    width: 120,
    height: 40
  },
  "pl_logo_ehi": {
    width: 40,
    height: 40
  },
  "pl_logo_facebook": {
    width: 24,
    height: 24
  },
  "pl_logo_github": {
    width: 24,
    height: 24
  },
  "pl_logo_instagram": {
    width: 24,
    height: 24
  },
  "pl_logo_linkedin": {
    width: 24,
    height: 24
  },
  "pl_logo_mastercard": {
    width: 65,
    height: 40
  },
  "pl_logo_otto-app": {
    width: 40,
    height: 40
  },
  "pl_logo_otto-deprecated": {
    width: 140,
    height: 52
  },
  "pl_logo_otto-payments": {
    width: 297,
    height: 40
  },
  "pl_logo_otto-up": {
    width: 156,
    height: 40
  },
  "pl_logo_otto": {
    width: 119,
    height: 40
  },
  "pl_logo_paypal-monogram": {
    width: 34,
    height: 40
  },
  "pl_logo_paypal": {
    width: 120,
    height: 40
  },
  "pl_logo_pinterest": {
    width: 24,
    height: 24
  },
  "pl_logo_play-store": {
    width: 135,
    height: 40
  },
  "pl_logo_sepa-horizontal": {
    width: 98,
    height: 40
  },
  "pl_logo_sepa-vertical": {
    width: 31,
    height: 40
  },
  "pl_logo_slogan": {
    width: 159,
    height: 40
  },
  "pl_logo_snapchat": {
    width: 24,
    height: 24
  },
  "pl_logo_tiktok": {
    width: 24,
    height: 24
  },
  "pl_logo_up": {
    width: 66,
    height: 40
  },
  "pl_logo_visa": {
    width: 124,
    height: 40
  },
  "pl_logo_xing": {
    width: 24,
    height: 24
  },
  "pl_logo_youtube": {
    width: 24,
    height: 24
  }
} as const;
