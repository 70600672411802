/**
 *
 *
 *
 *
 *
 */
export function dispatchCustomEvent<Detail>(
  element: Element,
  eventName: string,
  eventInit: CustomEventInit<Detail>,
): boolean {
  return element.dispatchEvent(new CustomEvent<Detail>(eventName, eventInit));
}

/**
 *
 *
 *
 */
export function isElement(e: Node | Element): e is Element {
  return e.nodeType === e.ELEMENT_NODE;
}

/**
 *
 *
 *
 *
 */
export function onAnimationEnd(
  element: Element | HTMLElement,
): Promise<PromiseSettledResult<Animation>[]> {
  return Promise.allSettled(element.getAnimations().map((animation) => animation.finished));
}
