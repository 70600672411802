import { tick } from "svelte";

type Props = Record<string, unknown>;

/**
 *
 *
 *
 *
 *
 */
export type PropertyChangeDetail<P = Props> = {
  [K in keyof P]?: {
    /*                               */
    previous: P[K];
    /*                              */
    current: P[K];
  };
};

export type PropertyChangeEvent<P = Record<string, unknown>> = CustomEvent<PropertyChangeDetail<P>>;

export function usePropertyChange(host: HTMLElement): (props: Props) => Promise<void> {
  let lastProps: Props;

  return async (props: Props): Promise<void> => {
    if (!lastProps) {
      /*                               */
      lastProps = props;
      return;
    }

    /*                                  */
    const detail: PropertyChangeDetail = {};

    Object.entries(props).forEach(([key, value]) => {
      /*                                    */
      if (value !== lastProps[key]) {
        detail[key] = { previous: lastProps[key], current: value };
        lastProps[key] = value;
      }
    });

    /*                            */
    if (Object.keys(detail).length === 0) return;

    await tick();
    host.dispatchEvent(new CustomEvent("oc-property-change", { bubbles: false, detail }));
  };
}
