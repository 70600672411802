/**
 *
 *
 */

let originalStyles = "";

export function disableScrolling(): void {
  const { scrollY } = window;
  originalStyles = document.body.style.cssText;
  document.body.style.cssText = `${originalStyles} position:fixed; width:100%; top:-${scrollY}px;`;
}

export function enableScrolling(): void {
  const scrollY = document.body.style.top;
  document.body.style.cssText = originalStyles;
  window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
}
