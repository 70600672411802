/**
 *
 *
 *
 *
 */

export function isSmallDot(dotIndex: number, activeDot: number, amountDots: number): boolean {
  if (amountDots <= 7) return false;

  /*                                                                          */
  if (activeDot < 4) {
    return dotIndex > 5;
  }

  /*                                                                          */
  if (activeDot > amountDots - 5) {
    return dotIndex < amountDots - 6;
  }

  /*                                                                         */
  return !(dotIndex >= activeDot - 2 && dotIndex <= activeDot + 2);
}
