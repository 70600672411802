import { closeContext, createContext } from "@otto-ec/tracking-bct";
import { untrack } from "svelte";
import { sheetScope } from "./utils";
import type { useExternalContent } from "./externalContent.svelte";

const log = /*           */ sheetScope.scope("tracking");

/**
 *
 *
 *
 *
 *
 *
 */
/*                                                                        */
export function useTrackingContext(externalContent: ReturnType<typeof useExternalContent>) {
  const state = $state({
    /**
 *
 */
    sheetId: null as string | null,
    /**
 *
 */
    isActive: false,
  });

  /**
 *
 */
  let contextOpen = false;

  $effect(() => {
    const { isActive, sheetId } = state;
    const { validUrl, rawContent } = externalContent;

    if ((isActive && rawContent !== null) || !contextOpen) return;
    if (import.meta.env.DEV) log.info("Closing tracking context for sheet", sheetId, validUrl);
    closeContext();

    contextOpen = false;
  });

  $effect(() => {
    const isActive = untrack(() => state.isActive);
    const { validUrl, rawContent } = externalContent;

    /*                                                      */
    /*                                                  */
    if (!isActive || !state.sheetId || !validUrl || rawContent === null || contextOpen) return;

    if (import.meta.env.DEV)
      log.info("Creating tracking context for sheet", state.sheetId, validUrl);
    createContext(state.sheetId, validUrl, { ts_OttoComponent: ["sheet@v1"] });

    contextOpen = true;
  });

  return state;
}
