import type { Action } from "svelte/action";

/**
 *
 *
 *
 *
 *
 *
 *
 *
 */
export const stopLabelClickPropagation: Action<HTMLElement, void> = (node) => {
  const handler = (event: MouseEvent): void => {
    if (!(event.target instanceof HTMLInputElement)) {
      event.stopPropagation();
    }
  };
  node.addEventListener("click", handler);
  return {
    destroy: () => node.removeEventListener("click", handler),
  };
};
