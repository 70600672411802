/*                                                   */

import { untrack } from "svelte";

type MayGetEventDetails<T> = T extends CustomEvent<infer U> ? U : T extends Event ? never : T;

export interface EventDispatcher<EventMap extends Record<string, any>> {
  /*                    */
  /*                                                                                                              */
  /*                                                                                                                                    */
  <Type extends keyof EventMap>(
    ...args: null extends MayGetEventDetails<EventMap[Type]>
      ? [
          type: Type,
          parameter?: MayGetEventDetails<EventMap[Type]> | null | undefined,
          options?: EventInit,
        ]
      : undefined extends MayGetEventDetails<EventMap[Type]>
        ? [
            type: Type,
            parameter?: MayGetEventDetails<EventMap[Type]> | null | undefined,
            options?: EventInit,
          ]
        : [type: Type, parameter: MayGetEventDetails<EventMap[Type]>, options?: EventInit]
  ): boolean;
}

/**
 *
 *
 *
 *
 *
 */
export function useEventDispatcher<Events extends Record<string, any>>(
  host: HTMLElement,
): EventDispatcher<Events> {
  return (...args) => {
    const [type, parameter, options] = args;
    const event = new CustomEvent(type as string, {
      bubbles: true,
      cancelable: true,
      composed: true,
      ...options,
      detail: parameter,
    });
    return untrack(() => host.dispatchEvent(event));
  };
}
