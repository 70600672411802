import type { Action } from "svelte/action";

type Parameters = { validationMessage?: string; hint?: string };

/**
 *
 *
 *
 *
 *
 */
export const ariaDescriptionWithValidation: Action<HTMLElement, Parameters> = (
  node,
  parameters,
) => {
  const update = ({ validationMessage, hint }: Parameters): void => {
    node.setAttribute("aria-description", [validationMessage, hint].filter(Boolean).join(" "));
    node.setAttribute("aria-invalid", `${!!validationMessage}`);
  };

  update(parameters);
  return { update };
};
