/**
 *
 *
 *
 *
 *
 *
 *
 *
 */
export const usePropertyInterceptor = (
  object: unknown,
  propertyKey: PropertyKey,
  { get, set }: { get?: () => void; set?: (newValue: unknown) => void },
): (() => void) => {
  if (!object) return () => {};
  const orig = Object.getOwnPropertyDescriptor(Object.getPrototypeOf(object), propertyKey)!;
  Object.defineProperty(object, propertyKey, {
    get() {
      get?.();
      return orig.get?.call(this);
    },
    set(newValue) {
      orig.set?.call(this, newValue);
      set?.(newValue);
    },
    configurable: true,
  });
  /*                                                            */
  return () => Object.defineProperty(object, propertyKey, orig);
};
