/**
 *
 *
 */
export const findNextPositionedParent = (event: Event): HTMLElement | undefined =>
  event
    .composedPath()
    .slice(1)
    .filter((e): e is HTMLElement => e instanceof HTMLElement)
    .find((e) => getComputedStyle(e).position !== "static");
