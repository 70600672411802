<svelte:options
  customElement={{
    tag: "oc-card-v2",
    shadow: "none",
    /*                       */
    extend: window.__components.extend({ delegateFocus: true }),
    props: {
      asButton: { type: "Boolean", attribute: "as-button" },
      base64Href: { type: "String", attribute: "base64-href" },
      href: { type: "String" },
      ocAriaLabel: { type: "String", attribute: "oc-aria-label" },
      rel: { type: "String" },
      target: { type: "String" },
    },
  }}
/>

<script lang="ts">
  import type { Props } from "./CardV2.types.js";
  import BasicCardV2 from "../../../common/components/Card/BasicCardV2.svelte";

  let {
    href = undefined,
    base64Href = undefined,
    asButton = false,
    ocAriaLabel = undefined,
    rel = undefined,
    target = undefined,
  }: Props = $props();

  const Host = $host<HTMLElement>();
</script>

<BasicCardV2 {Host} {asButton} bind:href {base64Href} {ocAriaLabel} {rel} {target}>
  {#snippet children(onslotchange)}
    <slot name="primary-action" {onslotchange} />
    <slot />
  {/snippet}
</BasicCardV2>

<style lang="scss" global>
  @use "@otto-ec/design-tokens/component" as tokens;
  @use "@otto-ec/otto-components-utils/scss/mixins";

  :host {
    @include mixins.no-tap-highlight();
    display: block;
    position: relative;
  }
</style>
