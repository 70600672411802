import { stringToDocumentFragment } from "@otto-ec/global-resources/dom";
import { parseParameters } from "@otto-ec/global-resources/attribute-parser";

/**
 *
 *
 */
import {
  catchError,
  flatMap,
  fromCondition,
  fromNullable,
  map,
  pipe,
  succeed,
  syncEffect,
  tap,
  trySync,
  type Effect,
} from "@otto-ec/assets-core-utils/nano-effect";
import type { OcSheetV1Props } from "../SheetV1.types.g";

const HEADLINE_SLOT_ATTR = 'slot="headline"';

/**
 *
 *
 *
 *
 *
 *
 */
export function parseLegacyContent(content: DocumentFragment): Effect<DocumentFragment, never> {
  pipe(
    /*                                                      */
    content.querySelector(`[${HEADLINE_SLOT_ATTR}]`),
    /*                                                           */
    syncEffect((slotExists) => fromCondition(!slotExists, true, false)),
    /*                                     */
    map(() => content.querySelector<HTMLElement>(`[data-title]`)?.dataset.title),
    /*                                                              */
    flatMap((headline) => fromCondition(!!headline, headline, null)),
    /*                             */
    map((headline) => `<h3 class="oc-headline-100" ${HEADLINE_SLOT_ATTR}>${headline}</h3>`),
    /*                                        */
    map(stringToDocumentFragment),
    /*                                               */
    tap((headlineSlotFragment) => content.append(headlineSlotFragment)),
  );
  /*                                          */
  return succeed(content);
}

export function getPropsFromContent(
  content: DocumentFragment,
): Effect<Partial<OcSheetV1Props>, never> {
  return pipe(
    /*                                                         */
    content.querySelector<HTMLScriptElement>("script[data-oc-sheet-v1]"),

    /*                                                            */
    syncEffect(fromNullable),

    /*                                                                 */
    tap((script) => content.removeChild(script)),

    /*                                           */
    flatMap((script) =>
      pipe(
        script.textContent!,
        /*                                             */
        trySync<Record<string, unknown>>(JSON.parse),
        /*                                           */
        catchError(() => succeed({} as Record<string, unknown>)),
        /*                                         */
        map((extraParameters) =>
          parseParameters<Partial<OcSheetV1Props>>(
            "data-oc-sheet-v1",
            "configure",
            script,
            undefined,
            { extraParameters },
          ),
        ),
      ),
    ),

    /*                                                 */
    catchError(() => succeed({} as Partial<OcSheetV1Props>)),
  );
}

export function updatePropsFromContent(
  host: HTMLOcSheetV1Element,
  forbiddenExternalProps: string[],
  props: Partial<OcSheetV1Props>,
): void {
  Object.entries(props).forEach(([key, value]) => {
    if (!forbiddenExternalProps.includes(key)) {
      host[key as never] = value as never;
    }
  });
}
