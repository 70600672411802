/**
 *
 *
 *
 *
 *
 *
 */
export function getAssociatedFormElements<ELEMENT extends HTMLElement & { name?: string }>(
  element: ELEMENT,
): ELEMENT[] {
  /*                                                         */
  /*         */
  const form = (element.form ?? element?.internals?.form) as HTMLFormElement | undefined;

  return Array.from(form?.elements ?? document.getElementsByTagName(element.tagName))
    .filter((ele): ele is ELEMENT => ele.tagName === element.tagName)
    .filter((ele) => ele.name === element.name);
}

type SubmitterElement = HTMLButtonElement | HTMLOcButtonV1Element;

/**
 *
 *
 *
 *
 *
 */
export const getDefaultSubmitter = (form?: HTMLFormElement | null): SubmitterElement | undefined =>
  (Array.from(form?.elements || []) as SubmitterElement[]).find(
    (element) => element.type === "submit",
  );
