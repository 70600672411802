<svelte:options
  customElement={{
    tag: "oc-floating-focus-v1",
    /*                       */
    extend: window.__components.extend(),
  }}
/>

<script lang="ts">
  import { onDestroy } from "svelte";
  import { repositionElement } from "./utils/floaterUtils";
  import { findNextPositionedParent } from "./utils/findNextPositionedParent";

  const UX_FLOATER_RADIUS = "0.01px";

  let floater = $state<HTMLElement>();
  let target = $state<HTMLElement | undefined>(undefined);
  let borderRadius: string = UX_FLOATER_RADIUS;

  let isVisible: boolean = $state(false);

  let monitorElementPositionInterval: NodeJS.Timeout;

  /*                                                                              */
  const onBlur = (event: FocusEvent): void => {
    const blurTarget = event.composedPath()[0];
    if (blurTarget instanceof HTMLElement) {
      blurTarget.classList.remove("oc-floating-focus-v1-hide-outline");
    }
  };

  const handleFloaterState = () => {
    if (!target) return;
    repositionElement(floater!, target, borderRadius);
  };

  const onFocus = (event: FocusEvent): void => {
    const focusTarget = event.composedPath()[0] as HTMLElement;

    if (
      event
        .composedPath()
        .some(
          (element) =>
            element instanceof HTMLElement && element.dataset.ocFloatingFocusV1Target !== undefined,
        )
    ) {
      focusTarget.classList.add("oc-floating-focus-v1-hide-outline");
      if (getComputedStyle(focusTarget).getPropertyValue("--oc-floating-focus-full-bleed")) {
        target = findNextPositionedParent(event);
      } else {
        target = focusTarget;
      }
    } else {
      target = undefined;
    }

    if (!target) return;

    const customBorderRadius = target.dataset.ocFloatingFocusV1Radius;

    if (customBorderRadius) {
      borderRadius = customBorderRadius;
    } else if (
      parseInt(window.getComputedStyle(target).borderRadius, 10) <
      parseInt(
        window
          .getComputedStyle(document.documentElement)
          .getPropertyValue("--oc-semantic-focus-outline-radius-12"),
        10,
      )
    ) {
      borderRadius = UX_FLOATER_RADIUS;
    } else {
      borderRadius = window.getComputedStyle(target).borderRadius;
    }

    handleFloaterState();
  };

  const handleMouseDown = (): void => {
    if (!target) return;
    target.classList.remove("oc-floating-focus-v1-hide-outline");
    isVisible = false;
  };

  const handleKeyDown = (e: KeyboardEvent): void => {
    if (!["Tab", "ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight"].includes(e.key)) return;
    isVisible = true;
  };

  let isScrolling = $state(false);
  let isScrollingInterval: NodeJS.Timeout;
  const handleScrollResize = () => {
    clearInterval(isScrollingInterval);
    isScrolling = true;

    handleFloaterState();

    isScrollingInterval = setInterval(() => {
      isScrolling = false;
    }, 100);
  };

  document.body.addEventListener("scroll", handleScrollResize, { passive: true, capture: true });
  onDestroy(() => {
    document.body.removeEventListener("scroll", handleScrollResize);
  });

  $effect(() => {
    clearInterval(monitorElementPositionInterval);
    if (isVisible && target) {
      monitorElementPositionInterval = setInterval(() => {
        if (target !== undefined) {
          handleFloaterState();
        }
      }, 250);
    }
  });

  /*                                                        */
  let noTransition = $state(false);
  $effect(() => {
    if (target && isVisible) {
      requestAnimationFrame(() => {
        noTransition = false;
      });
    } else {
      noTransition = true;
    }
  });
</script>

<svelte:window onresizecapture={handleScrollResize} />
<svelte:body
  onmousedown={handleMouseDown}
  onkeydown={handleKeyDown}
  onfocusincapture={onFocus}
  onblurcapture={onBlur}
/>
<div
  bind:this={floater}
  class="floating-focus"
  class:floating-focus--visible={isVisible && target}
  class:floating-focus--stopped={isScrolling || noTransition}
></div>

<style lang="scss" global>
  @use "@otto-ec/design-tokens/component" as tokens;

  .floating-focus {
    border: 0 solid currentColor;
    border-radius: inherit;
    position: absolute;
    transform: translate(-50%, -50%);
    opacity: 0;
    will-change: top, left, width, height, border-width, border-radius;
    box-sizing: content-box;
    pointer-events: none;
    overflow: hidden;
    z-index: 9999999999; /*                                                        */
    outline: tokens.$oc-semantic-focus-outline-radius-12 solid
      tokens.$oc-semantic-focus-outline-color;
    outline-offset: tokens.$oc-semantic-focus-outline-offset;

    @media (prefers-reduced-motion: no-preference) {
      transition-property: left, top, width, height, border-width, border-radius;
      transition-duration: 0.1s, 0.1s, 0.1s, 0.1s, 0.1s, 0.1s;
      transition-timing-function: ease, ease, ease, ease, ease, ease;
    }

    &--visible {
      opacity: 1;
    }

    &--stopped {
      transition: none;
    }
  }
</style>
