<svelte:options
  customElement={{
    tag: "oc-chip-v2",
    /*                                            */
    extend: window.__components.extend({
      formAssociated: true,
    }),
    props: {
      asButton: { type: "Boolean", attribute: "as-button" },
      base64Href: { type: "String", attribute: "base64-href" },
      checked: { type: "Boolean" },
      disabled: { type: "Boolean", reflect: true }, /*                                    */
      fillParent: { type: "Boolean", attribute: "fill-parent" },
      icon: { type: "String" },
      inverted: { type: "Boolean" },
      name: { type: "String", reflect: true }, /*                                    */
      ocAriaLabel: { type: "String", attribute: "oc-aria-label" },
      rel: { type: "String" },
      selection: { type: "String", attribute: "selection" },
      showCheckIcon: { type: "Boolean", attribute: "show-check-icon" },
      showRemoveIcon: { type: "Boolean", attribute: "show-remove-icon" },
      size: { type: "String" },
      target: { type: "String" },
      value: { type: "String" }, /*                                     */
    },
  }}
/>

<script lang="ts">
  import { exposeStatesForCSS } from "@otto-ec/otto-components-utils/utils";
  import {
    implicitSubmit,
    refireNonComposableNativeEvent,
    singleSelection as useSingleSelection,
    stopLabelClickPropagation,
  } from "../../../common/actions";
  import type { Props } from "./ChipV2.types";
  import { InteractiveElement } from "../../../common/components/InteractiveElement";
  import { useSlots } from "../../../common/utils/useSlots.svelte";

  let {
    asButton = false,
    base64Href = undefined,
    checked = false,
    disabled = false,
    fillParent = false,
    icon = undefined,
    inverted = false,
    name = undefined,
    ocAriaLabel = undefined,
    rel = undefined,
    showRemoveIcon = false,
    selection = undefined,
    showCheckIcon = false,
    size = "100",
    target = undefined,
    value = "on",
    internals,
  }: Props & {
    internals: ElementInternals;
  } = $props();

  const Host = $host();
  const slots = useSlots(Host);

  export function resetForm(): void {
    /*                            */
    checked = Host.hasAttribute("checked");
  }

  $effect(() => internals.setFormValue(checked ? value : null));

  $effect(() => exposeStatesForCSS(Host, { checked }));

  const onClick = (event: Event): void => {
    const input = event.target as HTMLInputElement;
    if (!event.defaultPrevented) checked = input.checked;
  };

  let chipClass = $derived(
    [
      "chip",
      `-size-${size}`,
      inverted && "-inverted",
      checked && "-checked",
      disabled && "-disabled",
      slots.image && "-has-image",
      !fillParent && "-fit-content",
    ]
      .filter(Boolean)
      .join(" "),
  );

  let iconSize = $derived<"50" | "100">(size === "100" ? "50" : "100");
  let iconType = $derived(checked && showCheckIcon ? "check" : icon);

  /*                                                                                                                       */
  let boldWidth = $state(0);
  $effect(() => {
    /*                                     */
    requestAnimationFrame(() => {
      /*                                     */
      requestAnimationFrame(() => {
        if (Host.shadowRoot?.querySelector(".__label") != null) {
          boldWidth = Host.shadowRoot!.querySelector(".__label")!.getBoundingClientRect().width;
        }
      });
    });
  });
</script>

{#snippet leftIcon()}
  {#if iconType}
    <oc-icon-v1 class="__icon" size={iconSize} type={iconType}></oc-icon-v1>
  {/if}
{/snippet}

{#snippet removableSnippet()}
  {#if showRemoveIcon}
    <oc-icon-v1 class="__icon" size={iconSize} type="close" ocAriaLabel="entfernen"></oc-icon-v1>
  {/if}
{/snippet}

{#if selection}
  <label
    class={`${chipClass} -interactive`}
    aria-label={ocAriaLabel}
    use:stopLabelClickPropagation
    data-oc-floating-focus-v1-target
    style="--bold-width: {boldWidth}px"
  >
    {@render leftIcon()}
    <slot name="image" class="__image" />
    <slot class="__label {boldWidth === 0 && !checked ? '-need-bold-width' : ''}" />
    {#if selection === "single"}
      <input
        type="radio"
        {checked}
        {name}
        {disabled}
        use:useSingleSelection={{ Host, name, checked }}
        use:refireNonComposableNativeEvent={Host}
        use:implicitSubmit={internals}
        onclick={onClick}
        aria-label={ocAriaLabel}
      />
    {:else}
      <input
        type="checkbox"
        bind:checked
        {name}
        {disabled}
        use:refireNonComposableNativeEvent={Host}
        use:implicitSubmit={internals}
        aria-label={ocAriaLabel}
      />
    {/if}
    {@render removableSnippet()}
  </label>
{:else}
  <InteractiveElement
    {Host}
    class={chipClass}
    bind:base64Href
    {asButton}
    {disabled}
    {rel}
    {target}
    aria-label={ocAriaLabel}
    style="--bold-width: {boldWidth}px"
  >
    {#snippet children(onslotchange)}
      {@render leftIcon()}
      <slot name="image" class="__image" />
      <slot
        class="__label {boldWidth === 0 && !checked ? '-need-bold-width' : ''}"
        {onslotchange}
      />
      {@render removableSnippet()}
    {/snippet}
  </InteractiveElement>
{/if}

<style lang="scss" global>
  @use "@otto-ec/design-tokens/component" as tokens;
  @use "@otto-ec/otto-components-utils/scss/mixins";
  @use "../../floating-focus/v1/FloatingFocusV1.shared" as floating-focus;

  :host {
    @include mixins.no-tap-highlight();
    display: block;
  }

  /*               */
  .chip,
  input,
  ::slotted(a) {
    all: unset;
  }

  .chip {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    min-width: tokens.$oc-component-chip-min-width;
    width: 100%;

    outline: none;
    outline-offset: 0;
    color: tokens.$oc-component-chip-text-color;
    background-color: tokens.$oc-component-chip-default-background-color;

    .__label {
      display: block;
      text-align: center;
      min-width: var(--bold-width);
    }

    &.-has-image .__image {
      display: block;
      overflow: hidden;
      border-radius: 50%;
      ::slotted(*) {
        max-width: 100%;
        height: 100%;
      }
    }
  }

  /*                          */
  input,
  ::slotted(a):before {
    content: "";
    display: block;
    position: absolute;
    inset: 0;
  }

  /*                        */
  .-fit-content {
    width: fit-content;
  }

  /*    */
  .chip {
    &.-size-100 {
      border-radius: 1.25rem;
      gap: tokens.$oc-component-chip-100-gap-x;
      font: tokens.$oc-component-chip-100-font;
      height: tokens.$oc-base-dimension-relative-32;
      padding: tokens.$oc-component-chip-100-spacing-y tokens.$oc-component-chip-100-spacing-x;
      &.-has-image {
        padding-left: tokens.$oc-component-chip-image-spacing-left;
      }

      .__icon {
        width: tokens.$oc-component-chip-100-icon-size;
        height: tokens.$oc-component-chip-100-icon-size;
      }
      .__image {
        width: tokens.$oc-component-chip-100-image-size;
        height: tokens.$oc-component-chip-100-image-size;
      }
    }

    &.-size-200 {
      border-radius: 1.5rem;
      gap: tokens.$oc-component-chip-200-gap-x;
      font: tokens.$oc-component-chip-200-font;
      height: tokens.$oc-base-dimension-relative-48;
      padding: tokens.$oc-component-chip-200-spacing-y tokens.$oc-component-chip-200-spacing-x;
      &.-has-image {
        padding-left: tokens.$oc-component-chip-image-spacing-left;
      }

      .__icon {
        width: tokens.$oc-component-chip-200-icon-size;
        height: tokens.$oc-component-chip-200-icon-size;
      }

      .__label {
        padding: 0 4px;
      }
      .__image {
        width: tokens.$oc-component-chip-200-image-size;
        height: tokens.$oc-component-chip-200-image-size;
      }
    }
  }

  /*             */
  .chip.-inverted {
    background-color: tokens.$oc-component-chip-inverted-background-color;
  }

  /*            */
  .chip.-checked {
    background-color: tokens.$oc-component-chip-selected-background-color;
    font-weight: tokens.$oc-component-chip-selected-font-weight;
  }

  /*                                                                                                    */
  .chip .__label.-need-bold-width {
    font-weight: tokens.$oc-component-chip-selected-font-weight;
    visibility: hidden;
    --bold-width: unset;
  }

  /*             */
  :host(:disabled) {
    .chip {
      background-color: tokens.$oc-component-chip-disabled-background-color;
      color: tokens.$oc-component-chip-disabled-text-color;

      .__icon {
        --color: #{tokens.$oc-component-chip-disabled-icon-color};
      }
    }
  }

  /*             */
  input,
  ::slotted(a) {
    @include floating-focus.full-bleed();
  }

  :host(:not(:disabled)) {
    /*                */
    .-interactive,
    ::slotted(a) {
      cursor: pointer;
    }

    .chip.-interactive,
    .chip.-light-dom-interactive {
      /*          */
      @media (hover: hover) {
        &:hover {
          background-color: tokens.$oc-component-chip-default-background-color-hover;
        }
        &.-checked:hover {
          background-color: tokens.$oc-component-chip-selected-background-color-hover;
        }
        &.-inverted:hover {
          background-color: tokens.$oc-component-chip-inverted-background-color-hover;
        }
      }

      /*           */
      &:active {
        background-color: tokens.$oc-component-chip-default-background-color-active;
      }
      &.-checked:active {
        background-color: tokens.$oc-component-chip-selected-background-color-active;
      }
      &.-inverted:active {
        background-color: tokens.$oc-component-chip-inverted-background-color-active;
      }
    }
  }
</style>
