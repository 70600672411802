<svelte:options
  customElement={{
    tag: "oc-checkbox-v1",
    shadow: "none",
    /*                                            */
    extend: window.__components.extend({
      formAssociated: true,
      delegateFocus: true,
      inputBehavior: true,
    }),
    props: {
      fitContent: { type: "Boolean", attribute: "fit-content" },
      name: { type: "String", reflect: true }, /*                                    */
      labelPlacement: { type: "String", attribute: "label-placement" },
      value: { type: "String" },
      checked: { type: "Boolean" }, /*                                     */
      variant: { type: "String" },
      disabled: { type: "Boolean", reflect: true }, /*                                    */
      ocAriaLabel: { type: "String", attribute: "oc-aria-label" },
    },
  }}
/>

<script lang="ts">
  import { exposeStatesForCSS } from "@otto-ec/otto-components-utils/utils";
  import { useSlots } from "../../../common/utils/useSlots.svelte";
  import {
    ariaDescriptionWithValidation,
    implicitSubmit,
    refireNonComposableNativeEvent,
    stopLabelClickPropagation,
  } from "../../../common/actions";
  import { FormLabelIconV1 } from "../../../common/components/FormLabelIcon";

  import type { Props } from "./CheckboxV1.types";

  let {
    name = undefined,
    labelPlacement = undefined,
    value = "on",
    checked = false,
    variant = "default",
    disabled = false,
    ocAriaLabel = undefined,
    hint = undefined,
    fitContent = false,
    validationMessage = undefined,
    internals,
  }: Props & {
    internals: ElementInternals;
    hint?: string;
    validationMessage?: string;
  } = $props();

  const Host = $host();

  const slots = useSlots(Host);

  export function resetForm() {
    /*                            */
    checked = Host.hasAttribute("checked");
  }

  const computedVariant = $derived(validationMessage ? "error" : variant);

  $effect(() => {
    internals.setFormValue(checked ? value : null);
  });

  $effect(() => {
    exposeStatesForCSS(Host, { checked });
  });
</script>

<label
  class="checkbox"
  class:checkbox--error={computedVariant === "error"}
  class:checkbox--fit-content={fitContent}
  use:stopLabelClickPropagation
>
  <!-- Left Label Placement -->
  {#if slots.default && labelPlacement === "left"}
    <span class="checkbox__label">
      <slot />
      <FormLabelIconV1 variant={computedVariant} />
    </span>
  {/if}

  <span>
    <input
      type="checkbox"
      class="checkbox__input"
      bind:checked
      {name}
      {disabled}
      use:refireNonComposableNativeEvent={Host}
      use:implicitSubmit={internals}
      use:ariaDescriptionWithValidation={{ validationMessage, hint }}
      aria-label={ocAriaLabel}
    />
    <!-- The box displays the outline and the icon. This is needed because input element doesn't support pseudo elements. -->
    <span class="checkbox__box"></span>
  </span>

  <!-- Right Label Placement -->
  {#if slots.default && labelPlacement !== "left"}
    <span class="checkbox__label">
      <slot />
      <FormLabelIconV1 variant={computedVariant} />
    </span>
  {/if}
</label>

<style lang="scss" global>
  @use "@otto-ec/otto-components-utils/scss/mixins";
  @use "@otto-ec/design-tokens/component" as tokens;

  :host {
    @include mixins.no-tap-highlight();
    display: block;
  }

  .checkbox {
    display: flex;
    gap: tokens.$oc-component-form-checkbox-gap-x;
    color: tokens.$oc-component-form-default-label-color;
    font: tokens.$oc-component-form-field-input-font;

    &--fit-content {
      width: fit-content;
    }
  }

  .checkbox__input {
    all: unset;
    position: absolute;
    width: tokens.$oc-component-form-checkbox-container-size;
    height: tokens.$oc-component-form-checkbox-container-size;

    /*                 */
    @include mixins.focus-styles(tokens.$oc-component-form-checkbox-border-radius);
  }

  .checkbox__label {
    flex: 1;
  }

  .checkbox__box {
    position: relative;
    display: block;
    width: tokens.$oc-component-form-checkbox-container-size;
    height: tokens.$oc-component-form-checkbox-container-size;

    outline: 1px solid tokens.$oc-component-form-default-border-color;
    border-radius: tokens.$oc-component-form-checkbox-border-radius;

    background-color: tokens.$oc-component-form-background-color;
  }

  /*                   */
  .checkbox__input:checked ~ .checkbox__box:before {
    $center-icon: calc(50% - tokens.$oc-component-form-checkbox-icon-size / 2);

    content: "";
    display: block;
    position: absolute;
    width: tokens.$oc-component-form-checkbox-icon-size;
    height: tokens.$oc-component-form-checkbox-icon-size;
    left: $center-icon;
    top: $center-icon;
    bottom: $center-icon;
    right: $center-icon;

    mask-image: url("/assets-static/icons/pl_icon_check50.svg");
    background-color: tokens.$oc-component-form-default-icon-color;
  }

  /*                 */
  .checkbox--error {
    color: tokens.$oc-component-form-error-label-color;

    .checkbox__box {
      outline-color: tokens.$oc-component-form-error-border-color;
    }

    .checkbox__input:checked ~ .checkbox__box:before {
      background: tokens.$oc-component-form-error-icon-color;
    }
  }

  /*                 */
  @media (hover: hover) {
    :host(:enabled) {
      .checkbox:hover {
        cursor: pointer;

        .checkbox__box {
          outline-width: tokens.$oc-semantic-focus-outline-offset;
        }
      }
    }
  }

  /*                  */
  :host(:enabled) {
    .checkbox__input:active ~ .checkbox__box {
      outline-width: tokens.$oc-semantic-focus-outline-offset;
    }

    .checkbox:not(.checkbox--error) .checkbox__input:active ~ .checkbox__box {
      outline-color: tokens.$oc-component-form-default-border-color-active;
    }
  }

  /*                    */
  :host(:disabled) {
    .checkbox {
      color: tokens.$oc-component-form-disabled-label-color;
    }

    .checkbox__box {
      outline-color: tokens.$oc-component-form-disabled-border-color;
      background-color: tokens.$oc-component-form-disabled-background-color;

      &:before {
        background: tokens.$oc-component-form-disabled-icon-color;
      }
    }
  }
</style>
