import { nanoid } from "@otto-ec/global-resources/misc";

/**
 *
 *
 *
 *
 *
 */
export function safeId(size?: number, prefix = "id-"): string {
  return prefix.concat(nanoid(size));
}
