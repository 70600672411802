<script lang="ts">
  /**
 *
 *
 *
 *
 */
  /*                                            */
  import { tick } from "svelte";
  /*                                            */
  import { on } from "svelte/events";
  import { useFullPathHrefAccessor } from "@otto-ec/otto-components-utils/use/full-path-href-accessor";
  import type { InteractiveElementProps } from "./InteractiveElementProps";

  let {
    asButton = false,
    href = $bindable(undefined),
    base64Href = $bindable(undefined),
    rel = undefined,
    target = undefined,
    thisElement = $bindable(undefined),
    Host,
    children,
    ...rest
  }: InteractiveElementProps = $props();

  useFullPathHrefAccessor(
    Host,
    () => href,
    (v) => {
      href = v;
    },
  );

  /*                                                     */
  let lightDomElement = $state<HTMLElement | undefined>();
  $effect(() => {
    if (!lightDomElement) return;

    if (lightDomElement instanceof HTMLAnchorElement) {
      if (rel) lightDomElement.rel = rel;
      if (target) lightDomElement.target = target;
    } else if (!(lightDomElement instanceof HTMLButtonElement) && base64Href) {
      lightDomElement.tabIndex = 0;
      lightDomElement.setAttribute("role", "link");
    }
  });

  const onslotchange = (ev: Event) => {
    const slot = ev.currentTarget as HTMLSlotElement;

    if (slot.name === "primary-action") {
      /*                                          */
      /*                                                */
      lightDomElement = slot
        .assignedNodes()
        .find((n): n is HTMLElement => n.nodeType === Node.ELEMENT_NODE);
    } else {
      /*                                                      */
      lightDomElement = slot
        .assignedNodes()
        .filter((n): n is HTMLElement => n.nodeType === Node.ELEMENT_NODE)
        .find((n): n is HTMLAnchorElement => n.tagName === "A");
    }
  };

  /**
 *
 *
 */
  const unmaskHref = async (ev: Event) => {
    if (!base64Href) return;

    let decodedHref = atob(base64Href);
    base64Href = undefined;

    if (lightDomElement) {
      if (lightDomElement instanceof HTMLAnchorElement) {
        lightDomElement.href = decodedHref;
      } else {
        /*                                                     */
        /*                                                                                                 */
        const anchorElement = document.createElement("a");

        /*                                                          */
        Array.from(lightDomElement.attributes).forEach(({ name, value }) =>
          anchorElement.setAttribute(name, value),
        );

        anchorElement.href = decodedHref;

        /*                                                           */
        anchorElement.append(...lightDomElement.childNodes);

        /*                                           */
        lightDomElement.parentNode?.replaceChild(anchorElement, lightDomElement);

        /*                                                */
        if (ev instanceof FocusEvent && lightDomElement === ev.target) {
          /*                    */
          /*                                                     */
          anchorElement.focus();
        }
      }
    } else {
      /*                                                         */

      /*                                                      */
      href = decodedHref;

      /*                                  */
      if (ev instanceof FocusEvent) {
        /*                                     */
        await tick();
        /*                    */
        /*                                                     */
        thisElement?.focus();
      }
    }
  };

  $effect(() => {
    if (!base64Href) return;

    /*                                           */
    const removeListenerList = ["focusin", "mouseover", "touchstart"].map((ev) =>
      on(thisElement!, ev, unmaskHref),
    );

    /*                                         */
    return () => removeListenerList.forEach((cb) => cb());
  });

  /*               */
  const tag = $derived.by(() => {
    if (lightDomElement || base64Href) return "div";
    if (href) return "a";
    if (asButton) return "button";
    return "div";
  });

  /*                                                                             */
  const tabindex = $derived(!lightDomElement && base64Href ? 0 : undefined);
  const role = $derived(!lightDomElement && base64Href ? "link" : undefined);

  /*                                  */
  let isInteractive = $state(false);
  $effect(() => {
    /*                                                  */
    tick().then(() => {
      isInteractive = tabindex === 0 || rest?.tabIndex === 0 || thisElement?.tabIndex === 0;
    });
  });

  /*                                                                        */
  let className = $derived(
    [rest.class, isInteractive && "-interactive", lightDomElement && "-light-dom-interactive"]
      .filter(Boolean)
      .join(" "),
  );
</script>

<!--order of props is important-->
<!--...$$restProps as late as possible to allow overwriting-->
<!--...$$restProps before class otherwise $$restProps.class overwrites class-->
<svelte:element
  this={tag}
  bind:this={thisElement}
  {tabindex}
  {role}
  {href}
  {rel}
  {target}
  {...rest}
  class={className}
  data-oc-floating-focus-v1-target
>
  {@render children(onslotchange)}
</svelte:element>
