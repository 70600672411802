<svelte:options
  customElement={{
    tag: "oc-switch-v1",
    shadow: "none",
    /*                                            */
    extend: window.__components.extend({ formAssociated: true, delegateFocus: true }),
    props: {
      name: { type: "String", reflect: true },
      size: { type: "String" },
      labelPlacement: { type: "String", attribute: "label-placement" },
      disabled: { type: "Boolean", reflect: true },
      checked: { type: "Boolean" },
      loading: { type: "Boolean" },
      ocAriaLabel: { type: "String", attribute: "oc-aria-label" },
    },
  }}
/>

<script lang="ts">
  import { fade } from "svelte/transition";

  import { useSlots } from "../../../common/utils/useSlots.svelte";
  import type { Props } from "./SwitchV1.types.js";

  let {
    /*                                                                                     */
    name = undefined,
    size = "100",
    labelPlacement = undefined,
    disabled = false,
    checked = false,
    loading = false,
    ocAriaLabel = undefined,
    value = "on",
    internals,
  }: Props & {
    internals: ElementInternals;
  } = $props();

  const Host = $host();

  const slots = useSlots(Host);

  $effect(() => {
    internals.setFormValue(checked ? value : null);
  });

  const handleClick = (event: MouseEvent | KeyboardEvent) => {
    if (loading) {
      event.stopPropagation();
      event.preventDefault();
      return;
    }

    checked = !checked;
  };
</script>

<button
  class="switch-button"
  onclick={handleClick}
  aria-label={ocAriaLabel}
  role="switch"
  aria-checked={checked}
  class:switch-button--loading={loading}
  {disabled}
>
  <!-- Left Label -->
  {#if slots.default && labelPlacement === "left"}
    <span>
      <slot />
    </span>
  {/if}
  <!-- The Switch -->
  <span class="switch switch--size-{size}">
    <span class="switch__handle switch__handle--size-{size}" aria-hidden="true">
      {#if loading}
        <oc-spinner-v1
          in:fade={{ duration: 200 }}
          class="switch__handle-spinner"
          variant="default"
          size={/*                      */ "50"}
        ></oc-spinner-v1>
      {:else if checked}
        <span class="switch__handle-checkmark"></span>
      {/if}
    </span>
  </span>

  <!-- Right Label -->
  {#if slots.default && labelPlacement !== "left"}
    <span>
      <slot />
    </span>
  {/if}
</button>

<style lang="scss" global>
  @use "@otto-ec/design-tokens/component" as tokens;
  @use "@otto-ec/otto-components-utils/scss/mixins";

  $borderRadius: 16px;

  :host {
    display: block;
    @include mixins.no-tap-highlight();
  }

  .switch-button {
    color: inherit;
    font: tokens.$oc-component-switch-label-font;
    display: flex;
    gap: 16px;
    align-items: center;
    appearance: none;
    border: none;
    background: none;
    padding: 0;
    margin: 0;
    outline: none;
    text-align: left;

    & {
      @include mixins.focus-styles($borderRadius, ".switch");
    }

    &:hover:enabled {
      cursor: pointer;
      &.switch-button--loading {
        cursor: progress;
      }
    }
    &:disabled .switch {
      pointer-events: none;
      background-color: tokens.$oc-component-switch-disabled-background-color;

      &:focus-visible {
        outline: none;
      }

      & > .switch__handle {
        background-color: tokens.$oc-component-switch-disabled-handle-color;
      }

      & > .switch__handle > .switch__handle-checkmark {
        background-color: tokens.$oc-component-switch-disabled-background-color;
      }

      &.switch--checked {
        background-color: tokens.$oc-component-switch-disabled-background-color;
      }
    }

    &:hover:enabled:not(.switch-button--loading) .switch {
      background-color: tokens.$oc-component-switch-default-background-color-hover;
    }

    &:active:enabled:not(.switch-button--loading) .switch {
      background-color: tokens.$oc-component-switch-default-background-color-active;
    }

    &[aria-checked="true"] {
      &:hover:enabled:not(.switch-button--loading) .switch {
        background-color: tokens.$oc-component-switch-checked-background-color-hover;
      }

      &:active:enabled:not(.switch-button--loading) .switch {
        background-color: tokens.$oc-component-switch-checked-background-color-active;
      }

      .switch {
        background-color: tokens.$oc-component-switch-checked-background-color;

        .switch__handle.switch__handle--size-50 {
          transform: translateX(10px);
        }

        .switch__handle.switch__handle--size-100 {
          transform: translateX(14px);
        }
      }
    }
  }

  /*                 */
  .switch {
    display: block;
    background-color: tokens.$oc-component-switch-default-background-color;
    transition:
      background-color tokens.$oc-component-switch-transition-duration,
      opacity 0.4s;
    border-radius: $borderRadius;
    flex: 1 0 auto;

    &--size-50 {
      width: 24px;
      height: 16px;
      padding: 4px 6px;
    }

    &--size-100 {
      height: 24px;
      width: 36px;
      padding: 4px 6px;
    }

    /*                   */
    &__handle {
      position: relative;
      display: block;
      background-color: var(--oc-base-color-white, #ffffff);
      transition: transform 100ms ease-out;
      border-radius: 50%;

      &--size-50 > &-checkmark {
        position: absolute;
        width: 12px;
        height: 12px;
        top: 2px;
        left: 2px;
        mask-image: url("/assets-static/icons/pl_icon_check50.svg");
        background-color: tokens.$oc-component-switch-checked-icon-color;
      }

      &--size-50 > &-spinner {
        position: absolute;
        width: 12px;
        height: 12px;
        top: 2px;
        left: 2px;
      }

      &--size-100 > &-checkmark {
        position: absolute;
        width: 16px;
        height: 16px;
        top: 4px;
        left: 4px;
        mask-image: url("/assets-static/icons/pl_icon_check50.svg");
        background-color: tokens.$oc-component-switch-checked-icon-color;
      }

      &--size-100 > &-spinner {
        position: absolute;
        width: 12px;
        height: 12px;
        top: 6px;
        left: 6px;
      }

      &--size-50 {
        width: 16px;
        height: 16px;
        transform: translateX(-2px);
      }

      &--size-100 {
        width: 24px;
        height: 24px;
        transform: translateX(-2px);
      }
    }
  }
</style>
