import "@otto-ec/design-tokens/theme/theme.scss";
import { version } from "@internal/package";
import { onReady } from "@otto-ec/global-resources/dom";
import { components } from "./common/component";
import { setColorScheme } from "./common/theme.js";

import.meta.glob("./common/extendCustomElement.ts", { eager: true });
import.meta.glob("./css-only-components/**/*.global.scss", { eager: true });
import.meta.glob("./fragments/**/*.global.scss", { eager: true });
import.meta.glob("./components/**/*.global.scss", { eager: true });

/*                                    */
import.meta.glob("./components/**/*.svelte", { eager: true });

/*                                                     */
const apis = Object.entries(
  import.meta.glob<true, string, Record<string, unknown>>("./components/**/*.api.ts", {
    eager: true,
  }),
).map(([path, module]) => {
  const name = path.match(/\/([^/]+)\.api\.ts$/)?.[1];

  if (import.meta.env.DEV && !name) {
    throw new Error(`Could not parse component name from path: ${path}`);
  }

  const key = name![0].toLowerCase() + name!.slice(1);
  const api = module[key];
  if (import.meta.env.DEV && !api) {
    throw new Error(`Module ${path} does not export ${key}`);
  }

  return { [key]: api };
});

/*                                           */
onReady.subscribe(() => {
  components.assignNamespace(Object.assign({ version: () => version, setColorScheme }, ...apis));
});

if (import.meta.env.STORYBOOK || window.o_util.toggle.get("oc_enable_floating_focus", true)) {
  /*                   */
  onReady.subscribe(() => {
    const focusTagname = "oc-floating-focus-v1";
    if (document.querySelector(focusTagname)) return;

    const floaterElement = document.createElement(focusTagname);
    document.body.append(floaterElement);
  });
}
