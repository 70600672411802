<svelte:options
  customElement={{
    tag: "oc-media-object-card-v1",
    /*                       */
    extend: window.__components.extend(),
    props: {
      size: { type: "String", attribute: "size" },
      variant: { type: "String", attribute: "variant" },
      asButton: { type: "Boolean", attribute: "as-button" },
      backgroundColor: { type: "String", attribute: "background-color" },
      base64Href: { type: "String", attribute: "base64-href" },
      fitContent: { type: "Boolean", attribute: "fit-content" },
      iconColor: { type: "String", attribute: "icon-color" },
      icon: { type: "String", attribute: "icon" },
      inverted: { type: "Boolean", attribute: "inverted" },
      ocAriaLabel: { type: "String", attribute: "oc-aria-label" },
      preferVerticalAlignment: { type: "Boolean", attribute: "prefer-vertical-alignment" },
      rel: { type: "String" },
      singleLine: { type: "Boolean", attribute: "single-line" },
      target: { type: "String" },
    },
  }}
/>

<script lang="ts">
  import type { Props } from "./MediaObjectCardV1.types.js";
  import BasicCardV2 from "../../../common/components/Card/BasicCardV2.svelte";

  let {
    variant = "ambient-image",
    asButton = false,
    backgroundColor = undefined,
    base64Href = undefined,
    fitContent = false,
    icon = undefined,
    iconColor = undefined,
    inverted = undefined,
    ocAriaLabel = undefined,
    preferVerticalAlignment = undefined,
    rel = undefined,
    size = "100",
    singleLine = undefined,
    target = undefined,
  }: Props = $props();
  const Host = $host();
</script>

<BasicCardV2
  class={`media-object-card media-object-card--${size} ${inverted ? "media-object-card--inverted" : ""} ${fitContent ? "media-object-card--fit-content" : "media-object-card--fill-parent"}`}
  {Host}
  {backgroundColor}
  {asButton}
  {ocAriaLabel}
  {rel}
  {target}
  bind:base64Href
>
  {#snippet children(onslotchange)}
    <slot name="primary-action" {onslotchange} />
    <!-- Image Wrapper -->
    {#if variant === "ambient-image" || variant === "cut-out-image"}
      <div
        class="media-object-card__image"
        class:media-object-card__image--cutout={variant === "cut-out-image"}
        class:media-object-card__image--ambient={variant === "ambient-image"}
      >
        <slot name="img" />
      </div>
    {/if}

    <!-- Icon Wrapper -->
    {#if variant === "icon" && icon}
      <div class="media-object-card__icon" class:media-object-card__icon--inverted={inverted}>
        <oc-icon-v1 type={icon} style="height: 100%; width: 100%" color={iconColor}></oc-icon-v1>
      </div>
    {/if}

    <!-- Text Wrapper -->
    <div
      class={`media-object-card__text media-object-card__text--${size}`}
      class:media-object-card__text--single-line={singleLine}
      class:media-object-card__text--inverted={inverted}
      class:media-object-card__text--prefer-vertical-alignment={preferVerticalAlignment}
    >
      <div class="media-object-card__text-headline">
        <slot name="headline" />
      </div>

      <div class="media-object-card__text-subline">
        <slot name="subline" />
      </div>
    </div>

    <div class="media-object-card__action-signifier">
      <slot name="action-signifier" />
    </div>
  {/snippet}
</BasicCardV2>

<style lang="scss" global>
  @use "@otto-ec/design-tokens/component" as tokens;
  @use "@otto-ec/otto-components-utils/scss/mixins";

  @mixin media-object-card-text-styles($size, $headline-font, $headline-line-clamp, $subline-font, $subline-line-clamp) {
    .media-object-card__text-headline {
      font: $headline-font;

      ::slotted(*) {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: $headline-line-clamp;
        overflow: hidden;
      }
    }

    .media-object-card__text-subline {
      font: $subline-font;

      ::slotted(*) {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: $subline-line-clamp;
        overflow: hidden;
      }
    }
  }

  :host {
    /*        */
    --background-color: unset;
    --icon-color: unset;
    display: block;
    container-type: inline-size;
    height: 100%;
  }

  ::slotted([slot="img"]) {
    display: block;
  }

  /*                                                */
  .media-object-card {
    &.media-object-card {
      position: relative;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      background-color: var(
        --background-color,
        #{tokens.$oc-component-media-object-card-default-background-color}
      );
      border-radius: tokens.$oc-component-media-object-card-border-radius;

      &--inverted {
        background-color: var(
          --background-color,
          #{tokens.$oc-component-media-object-card-inverted-background-color}
        );
      }

      &--fit-content {
        width: fit-content;
      }

      &--fill-parent {
        width: 100%;
      }

      /*       */
      &--100 {
        gap: tokens.$oc-component-media-object-card-100-gap-x;
        padding-right: tokens.$oc-component-media-object-card-100-spacing-right;
      }

      /*      */
      &--75 {
        gap: tokens.$oc-component-media-object-card-75-gap-x;
        padding-right: tokens.$oc-component-media-object-card-75-spacing-right;
      }

      /*      */
      &--50 {
        gap: tokens.$oc-component-media-object-card-50-gap-x;
        padding-right: tokens.$oc-component-media-object-card-50-spacing-right;
      }

      /*      */
      &--25 {
        gap: tokens.$oc-component-media-object-card-25-gap-x;
        padding-right: tokens.$oc-component-media-object-card-25-spacing-right;
      }
    }
  }

  .media-object-card {
    /*                                    */

    & > &__image--ambient {
      ::slotted([slot="img"]) {
        width: 100%;
        height: 100%;
      }
    }

    & > &__image--cutout {
      ::slotted([slot="img"]) {
        width: 100%;
        height: 100%;
        padding: 4px;
      }
    }

    &__image {
      flex-shrink: 0;
    }

    /*       */
    &--100 > &__image {
      width: tokens.$oc-component-media-object-card-100-s-m-image-width;
      height: tokens.$oc-component-media-object-card-100-s-m-image-width;
      padding-left: tokens.$oc-component-media-object-card-100-image-spacing-left;
      padding-top: tokens.$oc-component-media-object-card-100-image-spacing-y;
      padding-bottom: tokens.$oc-component-media-object-card-100-image-spacing-y;

      ::slotted([slot="img"]) {
        border-radius: tokens.$oc-component-media-object-card-100-image-border-radius;
      }

      @include mixins.breakpoint-from-l(false, true) {
        width: tokens.$oc-component-media-object-card-100-l-xl-image-width;
        height: tokens.$oc-component-media-object-card-100-l-xl-image-width;
      }
    }

    /*      */
    &--75 > &__image {
      height: tokens.$oc-component-media-object-card-75-image-width;
      width: tokens.$oc-component-media-object-card-75-image-width;
      padding-left: tokens.$oc-component-media-object-card-75-image-spacing-left;
      padding-top: tokens.$oc-component-media-object-card-75-image-spacing-y;
      padding-bottom: tokens.$oc-component-media-object-card-75-image-spacing-y;

      ::slotted([slot="img"]) {
        border-radius: tokens.$oc-component-media-object-card-75-image-border-radius;
      }
    }

    /*      */
    &--50 > &__image {
      height: tokens.$oc-component-media-object-card-50-image-width;
      width: tokens.$oc-component-media-object-card-50-image-width;
      padding-left: tokens.$oc-component-media-object-card-50-image-spacing-left;
      padding-top: tokens.$oc-component-media-object-card-50-image-spacing-y;
      padding-bottom: tokens.$oc-component-media-object-card-50-image-spacing-y;

      ::slotted([slot="img"]) {
        border-radius: tokens.$oc-component-media-object-card-50-image-border-radius;
      }
    }

    /*      */
    &--25 > &__image {
      height: tokens.$oc-component-media-object-card-25-image-width;
      width: tokens.$oc-component-media-object-card-25-image-width;
      padding-left: tokens.$oc-component-media-object-card-25-image-spacing-left;
      padding-top: tokens.$oc-component-media-object-card-25-image-spacing-y;
      padding-bottom: tokens.$oc-component-media-object-card-25-image-spacing-y;

      ::slotted([slot="img"]) {
        border-radius: tokens.$oc-component-media-object-card-25-image-border-radius;
      }
    }

    /*                                   */

    &__icon > oc-icon-v1 {
      --color: var(--icon-color, tokens.$oc-component-media-object-card-default-icon-color);
    }

    &__icon--inverted > oc-icon-v1 {
      --color: #{tokens.$oc-component-media-object-card-inverted-icon-color};
    }

    &__icon {
      flex-shrink: 0;
    }

    /*       */
    &--100 > &__icon {
      width: tokens.$oc-component-media-object-card-100-s-m-icon-size;
      height: tokens.$oc-component-media-object-card-100-s-m-icon-size;
      padding-left: tokens.$oc-component-media-object-card-100-icon-spacing-left;
      padding-top: tokens.$oc-component-media-object-card-100-s-m-icon-spacing-y;
      padding-bottom: tokens.$oc-component-media-object-card-100-s-m-icon-spacing-y;

      @include mixins.breakpoint-from-l(false, $containerFirst: true) {
        width: tokens.$oc-component-media-object-card-100-l-xl-icon-size;
        height: tokens.$oc-component-media-object-card-100-l-xl-icon-size;
        padding-top: tokens.$oc-component-media-object-card-100-l-xl-icon-spacing-y;
        padding-bottom: tokens.$oc-component-media-object-card-100-l-xl-icon-spacing-y;
      }
    }

    /*      */
    &--75 > &__icon {
      height: tokens.$oc-component-media-object-card-75-icon-size;
      width: tokens.$oc-component-media-object-card-75-icon-size;
      padding-left: tokens.$oc-component-media-object-card-75-icon-spacing-left;
      padding-top: tokens.$oc-component-media-object-card-75-icon-spacing-y;
      padding-bottom: tokens.$oc-component-media-object-card-75-icon-spacing-y;
    }

    /*      */
    &--50 > &__icon {
      height: tokens.$oc-component-media-object-card-50-icon-size;
      width: tokens.$oc-component-media-object-card-50-icon-size;
      padding-left: tokens.$oc-component-media-object-card-50-icon-spacing-left;
      padding-top: tokens.$oc-component-media-object-card-50-icon-spacing-y;
      padding-bottom: tokens.$oc-component-media-object-card-50-icon-spacing-y;
    }

    /*      */
    &--25 > &__icon {
      height: tokens.$oc-component-media-object-card-25-icon-size;
      width: tokens.$oc-component-media-object-card-25-icon-size;
      padding-left: tokens.$oc-component-media-object-card-25-icon-spacing-left;
      padding-top: tokens.$oc-component-media-object-card-25-icon-spacing-y;
      padding-bottom: tokens.$oc-component-media-object-card-25-icon-spacing-y;
    }

    /*                                   */
    &__text {
      display: flex;
      align-items: center;
      flex-direction: row;
      row-gap: 0;
      column-gap: tokens.$oc-component-media-object-card-single-line-gap-x;
      padding-top: tokens.$oc-component-media-object-card-spacing-y-min;
      padding-bottom: tokens.$oc-component-media-object-card-spacing-y-min;
      flex-wrap: wrap;
      color: tokens.$oc-component-media-object-card-default-text-color;

      &--inverted {
        color: tokens.$oc-component-media-object-card-inverted-text-color;
      }

      &--prefer-vertical-alignment {
        flex-direction: column;
        align-items: normal;
        gap: 0;
      }

      &--single-line {
        flex-direction: row;
        gap: tokens.$oc-component-media-object-card-single-line-gap-x;
      }

      &-headline {
        max-width: 100%;
      }
      &-subline {
        max-width: 100%;
      }

      /*       */
      &--100 {
        @include media-object-card-text-styles(
                        '100',
                        tokens.$oc-component-media-object-card-100-s-m-headline-font,
                        2,
                        tokens.$oc-component-media-object-card-100-copy-font,
                        2
        );

        @include mixins.breakpoint-from-l(false, true) {
          .media-object-card__text-headline {
            font: tokens.$oc-component-media-object-card-100-l-xl-headline-font;

            ::slotted(*) {
              -webkit-line-clamp: 1;
            }
          }
        }
      }


      /*      */
      &--75 {
        @include media-object-card-text-styles(
                        '75',
                        tokens.$oc-component-media-object-card-75-headline-font,
                        2,
                        tokens.$oc-component-media-object-card-75-copy-font,
                        1
        );
      }

      /*      */
      &--50 {
        @include media-object-card-text-styles(
                        '50',
                        tokens.$oc-component-media-object-card-50-headline-font,
                        2,
                        tokens.$oc-component-media-object-card-50-copy-font,
                        1
        );
      }

      /*      */
      &--25 {
        @include media-object-card-text-styles(
                        '25',
                        tokens.$oc-component-media-object-card-25-headline-font,
                        1,
                        tokens.$oc-component-media-object-card-25-copy-font,
                        1
        );
      }
    }

    /*                                        */
    &__action-signifier {
      margin-left: auto;
    }
  }
</style>
