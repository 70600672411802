import type { Props as IconProps } from "../../icon/v1/IconV1.types.js";
/**
 *
 */
export const iconTypeByBannerType = {
  error: "clear",
  success: "success-hint",
  warning: "warning-hint",
  hint: "question-hint",
  info: "info-hint",
} satisfies Record<string, IconProps["type"]>;
