<svelte:options
  customElement={{
    tag: "oc-interactive-overlay-v1",
    /*                       */
    extend: window.__components.extend(),
    props: {
      base64Href: { type: "String", attribute: "base64-href" },
      ocAriaLabel: { type: "String", attribute: "oc-aria-label" },
      rel: { type: "String" },
      target: { type: "String" },
    },
  }}
/>

<script lang="ts">
  import type { Props } from "./InteractiveOverlayV1.types";
  import { InteractiveElement } from "../../../common/components/InteractiveElement";

  let {
    base64Href = undefined,
    target = undefined,
    rel = undefined,
    ocAriaLabel = undefined,
  }: Props = $props();

  const Host = $host<HTMLElement>();
</script>

<InteractiveElement
  {Host}
  asButton={true}
  bind:base64Href
  {target}
  {rel}
  class="root"
  aria-label={ocAriaLabel}
>
  {#snippet children(onslotchange)}
    <slot {onslotchange} />
  {/snippet}
</InteractiveElement>

<style lang="scss" global>
  @use "@otto-ec/design-tokens/component" as tokens;
  @use "@otto-ec/otto-components-utils/scss/mixins";
  @use "../../floating-focus/v1/FloatingFocusV1.shared" as floating-focus;

  :host {
    @include mixins.no-tap-highlight();
    position: relative;
    display: block;
    width: fit-content;
  }

  .root {
    &,
    ::slotted(a) {
      display: block;
      text-decoration: inherit;
      color: inherit;
      background: none;
      border: none;
      padding: 0;
      outline: none;
      outline-offset: 0; /*                                        */
      font: inherit;
      cursor: pointer;
    }

    /*                                           */
    &.-interactive,
    ::slotted(a) {
      /*                    */
      @include floating-focus.full-bleed();

      &:before {
        content: "";
        position: absolute;
        inset: 0;
      }
    }

    /*                  */
    @media (hover: hover) {
      &.-interactive:hover,
      ::slotted(a:hover) {
        &:after {
          content: "";
          position: absolute;
          inset: calc(-1 * tokens.$oc-component-interactive-image-overlay-offset);
          border-radius: tokens.$oc-component-interactive-image-overlay-border-radius;
          background-color: tokens.$oc-component-interactive-image-overlay-hover;
          pointer-events: none;
        }
      }
    }

    /*                  */
    &.-interactive:active,
    ::slotted(a:active) {
      &:after {
        content: "";
        position: absolute;
        inset: calc(-1 * tokens.$oc-component-interactive-image-overlay-offset);
        border-radius: tokens.$oc-component-interactive-image-overlay-border-radius;
        background-color: tokens.$oc-component-interactive-image-overlay-active !important;
        pointer-events: none;
      }
    }
  }
</style>
