/**
 *
 *
 *
 */
export const getNextParentElement = (element: Element): HTMLElement | null =>
  /*                                */
  element.assignedSlot ??
  (element.parentNode instanceof ShadowRoot
    ? /*                                            */
      (element.parentNode.host as HTMLElement)
    : /*                                  */
      element.parentElement);
