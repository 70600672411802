import type { Action } from "svelte/action";
import { getAssociatedFormElements } from "../utils/form/form";

type RadioLikeElement = HTMLElement & {
  checked?: boolean;
  name?: string;
};

type ExtendedRadioLikeElement = RadioLikeElement & {
  setInputTabIndex?: (value: 0 | -1) => void;
  focusInput: () => void;
};

type SingleSelectionParameters = { Host: RadioLikeElement; checked?: boolean; name?: string };

/**
 *
 *
 *
 *
 *
 *
 *
 */
export const singleSelection: Action<HTMLInputElement, SingleSelectionParameters> = (
  input,
  parameters,
) => {
  const host = parameters.Host as ExtendedRadioLikeElement;

  /*                                                                              */
  host.setInputTabIndex = (value: 0 | -1) => (input.tabIndex = value);

  /*                                  */
  host.focusInput = () => input.focus();

  const handleArrowKeys = (event: KeyboardEvent): void => {
    const selectPrevious = ["ArrowUp", "ArrowLeft"].includes(event.code);
    const selectNext = ["ArrowDown", "ArrowRight"].includes(event.code);
    if (!selectPrevious && !selectNext) {
      return;
    }

    const elements = getAssociatedFormElements(host);
    const currentIndex = elements.findIndex((ele) => ele.checked);

    const nextElement: ExtendedRadioLikeElement | undefined = selectPrevious
      ? (elements[currentIndex - 1] ?? elements[elements.length - 1])
      : (elements[currentIndex + 1] ?? elements[0]);

    if (nextElement) {
      nextElement.checked = true;
      nextElement.focusInput();
      nextElement.click();
    }
    event.preventDefault();
  };

  input.addEventListener("keydown", handleArrowKeys);

  const update = ({ name, checked }: SingleSelectionParameters): void => {
    if (!name) return;

    const elements = getAssociatedFormElements(host);
    if (checked) {
      input.tabIndex = 0;

      /*                     */
      elements
        .filter((ele) => ele !== host)
        .forEach((ele) => {
          ele?.setInputTabIndex?.(-1);
          ele.checked = false;
        });
    } else if (elements[0] === host && elements.every((ele) => !ele.checked)) {
      /*                                                   */
      input.tabIndex = 0;
    } else {
      input.tabIndex = -1;
    }
  };

  /*                                                 */
  update(parameters);

  return {
    update,
    destroy: () => input.removeEventListener("keydown", handleArrowKeys),
  };
};
