/*                                 */
import { get, type Readable, type Unsubscriber } from "svelte/store";

import { toggle } from "@otto-ec/global-resources/toggle";
import type { SetRequired } from "type-fest";
import { onLoad, onReady } from "@otto-ec/global-resources/dom";
import type { next, back, close, initHistoryStack } from "./sheetHistory.js";
import { sheetScope } from "./utils.js";
import { CLOSE_TYPES, CLOSED_WITH_BACK_NAVIGATION } from "./events.svelte.js";
import type { OcSheetV1Props } from "../SheetV1.types.g.js";
import type { Props } from "../SheetV1.types.js";
import type { create as createInternal } from "../SheetV1.api.js";
import type { create as createNexus } from "../index.js";

/*            */
const windowHistory = window.history;
const scope = /*           */ sheetScope.scope("window-history");
const initHistoryLog = /*           */ scope.scope("initHistory");
const handlePopStateLog = /*           */ scope.scope("handlePopState");
const handleStackChangeLog = /*           */ scope.scope("handleStackChange");
const handleInstancePropsLog = /*           */ scope.scope("handleInstanceProps");
const createSheetInstanceIfNotExistsLog = /*           */ scope.scope(
  "createSheetInstanceIfNotExists",
);

export type SheetHistoryState = {
  /**
 *
 */
  sheetV1Id?: string;
  /**
 *
 */
  sheetV1Ids?: string[];
  /**
 *
 *
 */
  sheetV1Config?: OcSheetV1Props;
};

/**
 *
 *
 *
 */
const constrols = [true, true] as [isWindowHistoryActive: boolean, isSheetHistoryActive: boolean];
const isWindowHistoryActive = 0 satisfies keyof typeof constrols;
const isSheetHistoryActive = 1 satisfies keyof typeof constrols;

/**
 *
 */
export function sheetHistoryControlState(): typeof constrols {
  return [...constrols];
}

const popstateEvent = "popstate";

/**
 *
 *
 *
 *
 *
 */
/*                                                          */
export async function invokeSheetApi<F extends (...a: any[]) => unknown>(
  fn: F,
  ...args: Parameters<F>
): Promise<void> {
  if (import.meta.env.DEV) handlePopStateLog.info("invoke sheet api", fn.name, args);
  constrols[isSheetHistoryActive] = false;
  await fn(...args);
  constrols[isSheetHistoryActive] = true;
}

export function createSheetInstanceIfNotExists(
  state: SetRequired<SheetHistoryState, "sheetV1Id">,
  createFn: typeof createInternal | typeof createNexus,
): void {
  const sheetInstance = document.getElementById(state.sheetV1Id);
  if (!sheetInstance) {
    if (import.meta.env.DEV) createSheetInstanceIfNotExistsLog.info("create sheet instance", state);
    delete state.sheetV1Config?.open;
    invokeSheetApi(createFn, state.sheetV1Config as Omit<OcSheetV1Props, "open">);
  }
}

/**
 *
 *
 *
 *
 *
 */
export function handlePopState(
  sheetHistory: Readable<[current: string[], previous: string[]]>,
  closeFn: typeof close,
  nextFn: typeof next,
  backFn: typeof back,
  createFn: typeof createInternal | typeof createNexus,
  state: SheetHistoryState | undefined,
): void | Promise<void | void[]> {
  if (!constrols[isWindowHistoryActive]) {
    if (import.meta.env.DEV) handlePopStateLog.info("history update not active, skip", state);
    return undefined;
  }

  if (import.meta.env.DEV) handlePopStateLog.info("check current history state", state);

  const [current] = get(sheetHistory);
  const sheetId = state?.sheetV1Id;

  if (!sheetId && current.length > 0) {
    if (import.meta.env.DEV) handlePopStateLog.info("no sheet id in window.history, close all");

    return invokeSheetApi(closeFn, CLOSE_TYPES[CLOSED_WITH_BACK_NAVIGATION]);
  }

  if (!sheetId) {
    if (import.meta.env.DEV)
      handlePopStateLog.info("no sheet id in window.history, skip pop state");

    return undefined;
  }

  if (import.meta.env.DEV) handlePopStateLog.info("open from state, ensure sheet instance", state);
  createSheetInstanceIfNotExists(state as never, createFn);

  if (import.meta.env.DEV) handlePopStateLog.info("check lastIdIndex", sheetId, current);
  const lastIdIndex = current.lastIndexOf(sheetId);
  if (sheetId && lastIdIndex === -1) {
    if (import.meta.env.DEV)
      handlePopStateLog.info(
        "sheetId not found in history, but instance is in dom. open instance",
        sheetId,
      );

    return invokeSheetApi(nextFn, sheetId);
  }

  if (lastIdIndex !== -1) {
    const popIds = current.slice(lastIdIndex + 1);
    if (import.meta.env.DEV) handlePopStateLog.info("call back method for ids:", popIds);

    return Promise.all(
      popIds.map((popId) => {
        if (import.meta.env.DEV) handlePopStateLog.info("call back method fo sheet:", popId);
        return invokeSheetApi(backFn);
      }),
    );
  }

  return undefined;
}

/**
 *
 *
 *
 *
 *
 *
 */
export function initSheetHistory(
  initStackFn: typeof initHistoryStack,
  state: SheetHistoryState | undefined,
  createFn: typeof createInternal | typeof createNexus,
): void {
  if (import.meta.env.DEV) initHistoryLog.info("init sheet history", state);

  if (!state?.sheetV1Ids?.length || !state.sheetV1Id) {
    if (import.meta.env.DEV) initHistoryLog.info("nothing to restore", state);
    return;
  }

  if (import.meta.env.DEV) initHistoryLog.info("ensure sheet instance", state);
  createSheetInstanceIfNotExists(state as never, createFn);

  if (import.meta.env.DEV) initHistoryLog.info("restore sheet history state", state.sheetV1Ids);
  initStackFn(state.sheetV1Ids);
}

/**
 *
 */
export function handleStackChange([current, previous]: [
  current: string[],
  previous: string[],
]): void {
  if (!constrols[isSheetHistoryActive]) {
    if (import.meta.env.DEV)
      handleStackChangeLog.info("history update not active, skip", current, previous);
    return;
  }

  /*                                                                   */
  /*                                            */
  const state: SheetHistoryState | undefined = windowHistory.state;
  if (import.meta.env.DEV)
    handleStackChangeLog.info("sheet history from", previous, "to", current, "state", state);

  if (current.length === 0 && previous.length > 0) {
    if (import.meta.env.DEV)
      handleStackChangeLog.info("curent stack has been cleaned, push empty state");

    windowHistory.pushState(null, "", window.location.href);
    return;
  }

  const newSheetId = current.at(-1);
  if (!newSheetId) {
    if (import.meta.env.DEV) handleStackChangeLog.info("no sheet change, skip histroy update");
    return;
  }

  if (import.meta.env.DEV) handleStackChangeLog.info("check history for", newSheetId);
  /*                                                               */
  if (state?.sheetV1Id === newSheetId) {
    return;
  }

  if (import.meta.env.DEV) handleStackChangeLog.info("push next sheet", newSheetId);
  windowHistory.pushState(
    { sheetV1Id: newSheetId, sheetV1Ids: current } as SheetHistoryState,
    "",
    window.location.href,
  );
}

/**
 *
 *
 *
 *
 *
 */
export function handleInstanceProps(openSheetProps: Props | null): void {
  if (!constrols[isSheetHistoryActive]) {
    if (import.meta.env.DEV)
      handleStackChangeLog.info("history update not active, skip", openSheetProps);
    return;
  }

  const state = windowHistory.state as SheetHistoryState | undefined;

  if (!state?.sheetV1Id || !openSheetProps?.id || state.sheetV1Id !== openSheetProps.id) {
    if (import.meta.env.DEV)
      handleInstancePropsLog.info("skip instance props update", state, openSheetProps);
    return;
  }

  if (import.meta.env.DEV)
    handleInstancePropsLog.info("update instance props in history", openSheetProps);
  const updatedState: SheetHistoryState = { ...state, sheetV1Config: openSheetProps };
  windowHistory.replaceState(updatedState, "", window.location.href);
}

/**
 *
 *
 */
export function workaroundForStorybook(): void {
  const o = windowHistory.replaceState;
  Object.defineProperty(windowHistory, "replaceState", {
    /*                                                          */
    value(d: any, _: string, u: string) {
      /*                                           */
      if (windowHistory.state?.sheetV1Id && Object.keys(d).length < 1) {
        /*                                         */
        d = { ...windowHistory.state, ...d };
      }
      /*                                              */
      return o.call(windowHistory, d, _, u);
    },
  });
}

/**
 *
 *
 */
export function useWindowHistory(
  sheetHistory: Readable<[current: string[], previous: string[]]>,
  openSheetProps: Readable<Props | null>,
  initStackFn: typeof initHistoryStack,
  createFn: typeof createInternal | typeof createNexus,
  nextFn: typeof next,
  backFn: typeof back,
  closeFn: typeof close,
): Unsubscriber {
  const initEvent = import.meta.env.STORYBOOK ? onReady : onLoad;
  if (import.meta.env.STORYBOOK) workaroundForStorybook();

  /*                           */
  if (!import.meta.env.STORYBOOK && !toggle.get("oc_sheet_v1_use_window_history", false)) {
    scope.info("window history integration is disabled by toggle: oc_sheet_v1_use_window_history");
    return () => {};
  }

  /*                                                             */
  const state = windowHistory.state as SheetHistoryState | undefined;
  const unsubOnLoad = initEvent.subscribe(() => {
    initSheetHistory(initStackFn, state, createFn);
    unsubOnLoad();
  });

  /*                                                  */
  function popStateHandler(e: PopStateEvent): void {
    handlePopState(sheetHistory, closeFn, nextFn, backFn, createFn, e.state);
  }
  window.addEventListener(popstateEvent, popStateHandler);

  /*                                                        */
  const unsetHist = sheetHistory.subscribe(handleStackChange);

  /*                                                                */
  const unsetProps = openSheetProps.subscribe(handleInstanceProps);

  return () => {
    window.removeEventListener(popstateEvent, popStateHandler);
    unsetProps();
    unsetHist();
  };
}
