import type { AsyncNamespaceInput, FunctionImplementation } from "@otto-ec/global-resources/nexus";
import type { FocusedDialogV1 } from "./index";
import { closeParam, prefix, prevParam } from "./FocusedDialogV1.utils";
import { onReady } from "@otto-ec/global-resources/dom";

export const modifyLink = function modifyLinks(element, param) {
  const url = element.href;

  if (url) {
    const extendedUrl = new URL(url);
    const currentLocation = new URL(window.location.href);
    extendedUrl.searchParams.set(param, currentLocation.href);
    element.href = extendedUrl.href;
  }
} satisfies FunctionImplementation<FocusedDialogV1["modifyLink"]>;

export const focusedDialogV1: AsyncNamespaceInput<OttoComponentApi["focusedDialogV1"]> = {
  modifyLink,
};

const [prevAttribute, closeAttribute] = ["prev", "close"].map((k) => {
  const p = `data-${prefix}`;
  return `${p}\\.add-${k}-url`;
});

const map = [
  [prevAttribute, prevParam],
  [closeAttribute, closeParam],
] as const;

onReady.subscribe(() => {
  map.forEach(([key, value]) => {
    /*                                                                */
    document.querySelectorAll(`[${key}][href]`).forEach((element) => {
      modifyLink(element as HTMLElement & { href: string }, value);
    });

    new MutationObserver((mutationList) => {
      mutationList.forEach((mr) => {
        Array.from(mr.addedNodes)
          .filter((el) => {
            return "href" in el && el instanceof HTMLElement && el.matches(`[${key}]`);
          })
          .forEach((element) => {
            modifyLink(element as unknown as HTMLElement & { href: string }, value);
          });
        //
      });
    }).observe(document.body, {
      childList: true,
      subtree: true,
    });
  });
});
