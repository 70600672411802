<script lang="ts">
  interface Props {
    hint?: string | undefined;
    disabled?: boolean;
  }

  let { hint = undefined, disabled = false }: Props = $props();
</script>

{#if hint}
  <div class="hint" class:hint--disabled={disabled} aria-disabled={disabled}>{hint}</div>
{/if}

<style lang="scss" global>
  @use "@otto-ec/design-tokens/component" as tokens;

  .hint {
    margin-top: tokens.$oc-component-form-hint-gap-y;

    font: tokens.$oc-component-form-hint-font;
    color: tokens.$oc-component-form-default-hint-color;

    word-break: break-word; /*                                             */

    &--disabled {
      color: tokens.$oc-component-form-disabled-hint-color;
    }
  }
</style>
