import type { Action } from "svelte/action";
import { getDefaultSubmitter } from "../utils/form/form";

/**
 *
 *
 *
 *
 *
 */
export const implicitSubmit: Action<HTMLElement, ElementInternals> = (node, internals) => {
  const handler = (event: KeyboardEvent): void => {
    if (event.key === "Enter" && internals?.form) {
      /*                              */
      const submitter = getDefaultSubmitter(internals.form);

      if (submitter) {
        /*                                                                               */
        /*                                                */
        if (!submitter.disabled) {
          submitter.click();
        }
      } else {
        /*                                            */
        internals.form.requestSubmit();
      }
    }
  };

  node.addEventListener("keydown", handler);

  return {
    destroy: () => node.removeEventListener("keydown", handler),
  };
};
