import { readonly, writable, type Readable, type Unsubscriber } from "svelte/store";
import { getPointerType } from "@otto-ec/global-resources/device";
import { onReady } from "@otto-ec/global-resources/dom";
import { disableScrolling, enableScrolling } from "../../../../common/utils/scrollPrevention";
import { sheetScope } from "./utils";

const isTouchableDevice = writable(false);
const scrollLog = /*           */ sheetScope.scope("scroll-block");

/**
 *
 *
 */
export function useDeviceProperties(): {
  /**
 *
 */
  isTouchableDevice: Readable<boolean>;
} {
  getPointerType().then((pointerType) => {
    isTouchableDevice.set(pointerType.isTouchable);
  });

  return {
    isTouchableDevice: readonly(isTouchableDevice),
  };
}

/**
 *
 *
 *
 *
 *
 */
export function useDocumentScrollBlock(isSheetOpen: Readable<boolean>): Unsubscriber {
  /*                                                                      */
  /*                                           */
  if (import.meta.env.STORYBOOK && window.parent !== window.self) {
    if (import.meta.env.DEV)
      scrollLog.warn("running in storybook frame, skipping document scroll block");
    return () => {};
  }

  let unsetOpen: Unsubscriber;
  let documentScrollingDisabled = false;

  if (import.meta.env.DEV)
    scrollLog.info("Instanciating document scroll block, wait for dom ready");

  const unsetReady = onReady.subscribe(() => {
    /*                                                                              */

    if (import.meta.env.DEV) scrollLog.info("Document ready, setting up document scroll block");
    unsetOpen = isSheetOpen.subscribe((disable) => {
      /*                                                       */
      if (disable && !documentScrollingDisabled) {
        if (import.meta.env.DEV) scrollLog.info("Disabling document scrolling");
        disableScrolling();
      }

      if (!disable && documentScrollingDisabled) {
        if (import.meta.env.DEV) scrollLog.info("Enabling document scrolling");
        enableScrolling();
      }

      documentScrollingDisabled = disable;
    });

    if (import.meta.env.DEV)
      scrollLog.info("Document scroll block ready, unsubscribing from dom ready");
    unsetReady();
  });

  if (import.meta.env.DEV) scrollLog.info("Document scroll block setup complete");
  return () => unsetOpen();
}
