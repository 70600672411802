import {
  type AsyncNamespaceInput,
  type FunctionImplementation,
} from "@otto-ec/global-resources/nexus";
import type { SnackbarV1 } from "./index.js";

export const openSnackbar = function openSnackbar(id: string) {
  const snackbar = document.getElementById(id) as HTMLOcSnackbarV1Element | null;

  if (snackbar) {
    snackbar.open = true;
  }
} satisfies FunctionImplementation<SnackbarV1["open"]>;

export const closeSnackbar = function closeSnackbar(id: string) {
  const snackbar = document.getElementById(id) as HTMLOcSnackbarV1Element | null;

  if (snackbar) {
    snackbar.open = false;
  }
} satisfies FunctionImplementation<SnackbarV1["close"]>;

export const closeAllSnackbars = function closeAllSnackbars() {
  const snackbars = document.querySelectorAll("oc-snackbar-v1");

  snackbars.forEach((snackbar) => {
    snackbar.open = false;
  });
} satisfies FunctionImplementation<SnackbarV1["closeAll"]>;

/*                                                                               */
export const snackbarV1: AsyncNamespaceInput<OttoComponentApi["snackbarV1"]> = {
  open: openSnackbar,
  close: closeSnackbar,
  closeAll: closeAllSnackbars,
};
