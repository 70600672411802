<script lang="ts">
  interface Props {
    /**
 *
 */
    validationMessage?: string | undefined;
  }

  let { validationMessage = undefined }: Props = $props();
</script>

{#if validationMessage}
  <div class="validation-message">{validationMessage}</div>
{/if}

<style lang="scss" global>
  @use "@otto-ec/design-tokens/component" as tokens;

  .validation-message {
    margin-top: tokens.$oc-component-form-hint-gap-y;
    font: tokens.$oc-component-form-hint-font;
    color: tokens.$oc-component-form-error-validation-message-color;
  }
</style>
