/**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
export function useFullPathHrefAccessor(
  host: HTMLElement & { base64Href?: string },
  getHref: () => string | undefined,
  setHref: (value: string) => unknown,
): void {
  Object.defineProperty(host, "href", {
    get() {
      let href = getHref();
      href ??= host.base64Href ? atob(host.base64Href) : undefined;
      if (!href) return href; /*                */

      /*                                           */
      const a = document.createElement("a");
      a.href = href; /*       */

      /*                           */
      return a.href;
    },
    set: (val) => {
      setHref(val);
      /*                           */
      if (host.base64Href) {
        host.base64Href = btoa(val);
      }
    },
    configurable: true,
  });
}
