import { getNextParentElement } from "./getNextParentElement";

/**
 *
 *
 *
 *
 *
 */
export function currentZIndex(element: Element | null): number {
  if (!element) return 0;
  const { zIndex } = window.getComputedStyle(element);
  return zIndex === "auto" ? currentZIndex(getNextParentElement(element)) : parseInt(zIndex, 10);
}
