/*                                     */
import type { EventTopic, ExtendedPromise } from "@otto-ec/global-resources/nexus";
import { components, type OttoComponents } from "../../../common/component.js";
import type { GetSheetDetailByName } from "./lib/events.svelte.js";
import type { OcSheetV1Props } from "./SheetV1.types.g";

declare global {
  interface OttoComponentApi {
    /**
 *
 *
 */
    sheetV1: {
      /**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
      open: (sheetID: string) => ExtendedPromise<void>;

      /**
 *
 *
 *
 *
 */
      close: () => ExtendedPromise<void>;

      /**
 *
 *
 *
 *
 *
 *
 *
 */
      back: () => ExtendedPromise<void>;

      /**
 *
 *
 *
 *
 *
 *
 */
      create: (
        config?: Partial<
          Omit<OcSheetV1Props, "open"> & {
            /**
 *
 *
 *
 */
            open: true;
            /**
 *
 *
 */
            dataSet: Record<string, string>;
          }
        >,
      ) => ExtendedPromise<HTMLOcSheetV1Element>;

      /**
 *
 */
      getOpenSheet: () => ExtendedPromise<HTMLOcSheetV1Element | null>;

      /**
 *
 *
 */
      getContent: () => ExtendedPromise<HTMLElement | null>;

      /**
 *
 *
 */
      getHeader: () => ExtendedPromise<HTMLElement | null>;

      /**
 *
 *
 */
      getActions: () => ExtendedPromise<HTMLElement | null>;

      /**
 *
 */
      events: {
        /**
 *
 *
 *
 *
 *
 *
 */
        beforeOpen: EventTopic<GetSheetDetailByName<"oc-before-open">>;
        /**
 *
 *
 *
 *
 *
 *
 */
        open: EventTopic<GetSheetDetailByName<"oc-open">>;
        /**
 *
 */
        afterOpen: EventTopic<GetSheetDetailByName<"oc-after-open">>;

        /**
 *
 *
 */
        beforeClose: EventTopic<GetSheetDetailByName<"oc-before-close">>;
        /**
 *
 *
 *
 */
        close: EventTopic<GetSheetDetailByName<"oc-close">>;
        /**
 *
 */
        afterClose: EventTopic<GetSheetDetailByName<"oc-after-close">>;

        /**
 *
 *
 *
 */
        beforeContentChange: EventTopic<GetSheetDetailByName<"oc-before-content-change">>;

        /**
 *
 *
 *
 */
        contentChangeShow: EventTopic<GetSheetDetailByName<"oc-content-change-show">>;

        /**
 *
 *
 *
 */
        contentChangeHide: EventTopic<GetSheetDetailByName<"oc-content-change-hide">>;

        /**
 *
 *
 *
 *
 *
 */
        contentLoaded: EventTopic<GetSheetDetailByName<"oc-content-loaded">>;

        /**
 *
 *
 *
 *
 */
        contentLoadingError: EventTopic<GetSheetDetailByName<"oc-content-loading-error">>;

        /*                                                 */
        switch: EventTopic<GetSheetDetailByName<"oc-switch">>;
      };

      /*                                                  */
      beforeOpenEvent: EventTopic<GetSheetDetailByName<"oc-before-open">>;
      /*                                                  */
      openEvent: EventTopic<GetSheetDetailByName<"oc-open">>;
      /*                                                  */
      afterOpenEvent: EventTopic<GetSheetDetailByName<"oc-after-open">>;
      /*                                                  */
      beforeCloseEvent: EventTopic<GetSheetDetailByName<"oc-before-close">>;
      /*                                                  */
      closeEvent: EventTopic<GetSheetDetailByName<"oc-close">>;
      /*                                                  */
      afterCloseEvent: EventTopic<GetSheetDetailByName<"oc-after-close">>;
      /*                                                  */
      beforeContentChangeEvent: EventTopic<GetSheetDetailByName<"oc-before-content-change">>;
      /*                                                  */
      contentChangeShowEvent: EventTopic<GetSheetDetailByName<"oc-content-change-show">>;
      /*                                                  */
      contentChangeHideEvent: EventTopic<GetSheetDetailByName<"oc-content-change-hide">>;
      /*                                                  */
      switchEvent: EventTopic<GetSheetDetailByName<"oc-switch">>;
      /*                                                  */
      contentLoadedEvent: EventTopic<GetSheetDetailByName<"oc-content-loaded">>;
      /*                                                  */
      contentLoadingErrorEvent: EventTopic<GetSheetDetailByName<"oc-content-loading-error">>;
    };
  }
}

/*                                   */
export type SheetV1 = OttoComponents["sheetV1"];
/*                                                  */
export const sheet: SheetV1 = components.sheetV1;
/*                                                */
export const open: SheetV1["open"] = sheet.open;
/*                                                 */
export const close: SheetV1["close"] = sheet.close;
/*                                                */
export const back: SheetV1["back"] = sheet.back;
/*                                                  */
export const create: SheetV1["create"] = sheet.create;
/*                                                        */
export const getOpenSheet: SheetV1["getOpenSheet"] = sheet.getOpenSheet;
/*                                                     */
export const getHeader: SheetV1["getHeader"] = sheet.getHeader;
/*                                                      */
export const getContent: SheetV1["getContent"] = sheet.getContent;
/*                                                      */
export const getActions: SheetV1["getActions"] = sheet.getActions;
/*                     */
export const events: SheetV1["events"] = sheet.events;

/*                                          */
export const openEvent: SheetV1["openEvent"] = sheet.openEvent;
/*                                          */
export const closeEvent: SheetV1["closeEvent"] = sheet.closeEvent;
/*                                          */
export const contentLoadedEvent: SheetV1["contentLoadedEvent"] = sheet.contentLoadedEvent;
/*                                          */
export const contentLoadingErrorEvent: SheetV1["contentLoadingErrorEvent"] =
  sheet.contentLoadingErrorEvent;
