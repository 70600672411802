import { componentsScope } from "../../../../common/utils/logger.js";
import type { Props } from "../SheetV1.types.js";
import { safeId } from "@otto-ec/otto-components-utils/utils/safeId";

export const sheetScope = /*           */ componentsScope.scope("sheet-v1");

export const sheetProps = [
  /*                     */
  "id",
  "url",
  "base64Url",
  "open",
  "allowedErrorStatusCodes",
  "forbiddenExternalProps",
  "extraData",
  /*            */
  "headline",
  "hideHeader",
  "hideCloseButton",
  "fullHeight",
  "ocAriaLabel",
  "noContentPadding",
] as const satisfies (keyof Props)[];

export function applyProps(sheet: HTMLOcSheetV1Element, config: Partial<Props>): string {
  Object.entries(config).forEach(([key, value]) => {
    /*                                                     */
    sheet[key] = value;
  });

  /*                             */
  sheet.id ??= safeId();

  return sheet.id;
}
