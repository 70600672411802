<script lang="ts">
  interface Props {
    disabled?: boolean;
    counterValue?: string | undefined;
    minCounter?: number | undefined;
    maxCounter?: number | undefined;
  }

  let {
    disabled = false,
    counterValue = undefined,
    minCounter = undefined,
    maxCounter = undefined,
  }: Props = $props();

  let minCounterText = $derived(minCounter === undefined ? "" : ` (min. ${minCounter})`);
  let maxCounterText = $derived(maxCounter === undefined ? "" : ` (max. ${maxCounter})`);
  let counterText = $derived(`${counterValue?.length ?? 0}${minCounterText}${maxCounterText}`);
</script>

{#if typeof (minCounter ?? maxCounter) === "number"}
  <!-- aria-hidden -> because minlength/maxlength is set on input element -->
  <small
    class="counter"
    class:counter--disabled={disabled}
    aria-hidden="true"
    aria-disabled={disabled}>{counterText}</small
  >
{/if}

<style lang="scss" global>
  @use "@otto-ec/design-tokens/component" as tokens;

  .counter {
    flex: 0 0 auto;
    margin-top: tokens.$oc-component-form-hint-gap-y;

    font: tokens.$oc-component-form-hint-font;
    color: tokens.$oc-component-form-default-hint-color;

    &--disabled {
      color: tokens.$oc-component-form-disabled-hint-color;
    }
  }
</style>
