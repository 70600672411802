import { useEventDispatcher } from "@otto-ec/otto-components-utils/use/event-dispatcher";
import type { CamelCase } from "type-fest";
import { get, type Readable } from "svelte/store";
import { camelcasify } from "@otto-ec/assets-core-utils/string";
import { sheet } from "../index.js";
import type { Events } from "../SheetV1.types";
import type { useExternalContent } from "./externalContent.svelte";
import { sheetScope } from "./utils.js";

const log = /*           */ sheetScope.scope("events");

/**
 *
 */
export type GetSheetDetailByName<T extends keyof Events> =
  Events[T] extends CustomEvent<infer U> ? U : never;

type GetDetailByEventName<T> = T extends keyof Events ? GetSheetDetailByName<T> : never;

type GetSheetDetailById<T extends EventsKeys> = GetDetailByEventName<(typeof EVENTS)[T][0]>;

type AsEventKey<T> = T extends `oc-${infer U}` ? U : never;

type AsEventPair<T extends keyof Events> = [T, CamelCase<AsEventKey<T>>, `${CamelCase<AsEventKey<T>>}Event`];

function eventPair<T extends keyof Events>(key: T): AsEventPair<T> {
  const event = camelcasify(key.replace("oc-", "") as AsEventKey<T>);
  return [key, event, `${event}Event`] as const;
}

type NumberMap = { [K in 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 as `${K}`]: K };
type AsKey<T> = T extends `${infer U}` ? (U extends keyof NumberMap ? NumberMap[U] : never) : never;
type EventsKeys = AsKey<keyof typeof EVENTS>;

const EVENTS = [
  eventPair("oc-before-open"),
  eventPair("oc-open"),
  eventPair("oc-after-open"),
  eventPair("oc-before-close"),
  eventPair("oc-close"),
  eventPair("oc-after-close"),
  eventPair("oc-before-content-change"),
  eventPair("oc-content-change-show"),
  eventPair("oc-content-change-hide"),
  eventPair("oc-content-loaded"),
  eventPair("oc-content-loading-error"),
  eventPair("oc-switch"),
] as const;

const beforeOpenEvent = 0 satisfies EventsKeys;
const openEvent = 1 satisfies EventsKeys;
const afterOpenEvent = 2 satisfies EventsKeys;
const beforeCloseEvent = 3 satisfies EventsKeys;
const closeEvent = 4 satisfies EventsKeys;
const afterCloseEvent = 5 satisfies EventsKeys;
const beforeContentChangeEvent = 6 satisfies EventsKeys;
const contentChangeShowEvent = 7 satisfies EventsKeys;
const contentChangeHideEvent = 8 satisfies EventsKeys;
const contentLoadedEvent = 9 satisfies EventsKeys;
const contentLoadingErrorEvent = 10 satisfies EventsKeys;
/*                              */
const deprecatedSwitchEvent = 11 satisfies EventsKeys;

type CloseTypeKeys = AsKey<keyof typeof CLOSE_TYPES>;

export type CloseTypes =
  | "closedWithX"
  | "closedWithCurtainClick"
  | "closedWithDrag"
  | "closedWithEscape"
  | "closedProgrammatically"
  | "closedWithBackNavigation";

export const CLOSE_TYPES = [
  "closedWithX",
  "closedWithCurtainClick",
  "closedWithDrag",
  "closedWithEscape",
  "closedProgrammatically",
  "closedWithBackNavigation",
] as const satisfies CloseTypes[];

export const CLOSED_WITH_X = 0 satisfies CloseTypeKeys;
export const CLOSED_WITH_CURTAIN_CLICK = 1 satisfies CloseTypeKeys;
export const CLOSED_WITH_DRAG = 2 satisfies CloseTypeKeys;
export const CLOSED_WITH_ESCAPE = 3 satisfies CloseTypeKeys;
export const CLOSED_PROGRAMMATICALLY = 4 satisfies CloseTypeKeys;
export const CLOSED_WITH_BACK_NAVIGATION = 5 satisfies CloseTypeKeys;

/**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
export function useSheetEvents(
  host: HTMLOcSheetV1Element,
  sheetHostElementVisible: Readable<boolean>,
  sheetElementVisible: Readable<boolean>,
  sheetElementFullyOpened: Readable<boolean>,
  isActiveSheet: Readable<boolean>,
  isSwitching: Readable<boolean>,
  requestedClose: Readable<CloseTypes | null>,
  extraData: Record<string, unknown> | null,
  externalContent: ReturnType<typeof useExternalContent>,
) {
  const dispatch = useEventDispatcher<Events>(host);

  /**
 *
 */
  function emitEvent<T extends EventsKeys>(
    eventId: T,
    payload: Omit<GetSheetDetailById<T>, "instance" | "extraData">,
  ): void {
    /*                                        */
    dispatch(EVENTS[eventId][0], { ...payload, instance: host, extraData }, { cancelable: false });

    /*                                                         */
    /*                                           */
    /*                                   */
    /*                                                 */

    /*                                                                            */
    sheet.events[EVENTS[eventId][1]].emit(
      /*                                                                                     */
      { ...payload, instance: host, extraData },
    );
    sheet[EVENTS[eventId][2]].emit(
      /*                                                                                     */
      { ...payload, instance: host, extraData },
    );
  }

  /*                                                               */
  let init = false;
  let closeType: CloseTypes = CLOSE_TYPES[CLOSED_PROGRAMMATICALLY];

  isActiveSheet.subscribe((isActive) => {
    if (import.meta.env.DEV)
      log.info("Active State changed", host.id, isActive ? "active" : "inactive");
    if (isActive) emitEvent(get(isSwitching) ? beforeContentChangeEvent : beforeOpenEvent, {});
  });

  sheetElementVisible.subscribe((visible) => {
    if (!init) return;

    const contentChangeInProgress = get(isSwitching);
    if (visible) {
      if (contentChangeInProgress) {
        /*                                                        */
        emitEvent(contentChangeShowEvent, {  });
        emitEvent(deprecatedSwitchEvent, {});

        return;
      }

      emitEvent(openEvent, {});
      return;
    }

    if (import.meta.env.DEV) log.info("Sheet not visible", host.id, closeType);
    emitEvent(contentChangeInProgress ? contentChangeHideEvent : closeEvent, { closeType });
  });

  /*                                                    */
  /*                                                                                */
  sheetElementFullyOpened.subscribe((visible) => {
    if (visible && !get(isSwitching)) emitEvent(afterOpenEvent, {});
  });

  /*                                                              */
  requestedClose.subscribe((type) => {
    if (type) {
      closeType = type;
      if (import.meta.env.DEV) log.info("REQUESTED CLOSE", host.id, closeType);
    }

    if (type && get(isActiveSheet) && !get(isSwitching)) {
      emitEvent(beforeCloseEvent, { closeType });
    }
  });

  sheetHostElementVisible.subscribe((visible) => {
    if (!init) return;

    if (!visible && !get(isSwitching)) {
      emitEvent(afterCloseEvent, { closeType });
      closeType = CLOSE_TYPES[CLOSED_PROGRAMMATICALLY];

      if (import.meta.env.DEV)
        log.info("Host visible changed", host.id, visible ? "visible" : "invisible", closeType);
    }
  });

  /*                                                                       */
  /*               */
  $effect(() => {
    if (externalContent.isApplied) emitEvent(contentLoadedEvent, {});
  });

  /*                                                          */
  /*                                                                      */
  $effect(() => {
    if (externalContent.rawError)
      emitEvent(contentLoadingErrorEvent, { error: externalContent.rawError });
  });

  init = true;
}
