<svelte:options
  customElement={{
    tag: "oc-selection-tile-v1",
    /*                                            */
    extend: window.__components.extend({ formAssociated: true, inputBehavior: true }),
    props: {
      name: { type: "String", reflect: true }, /*                                    */
      value: { type: "String" },
      checked: { type: "Boolean" }, /*                                     */
      variant: { type: "String" },
      disabled: { type: "Boolean", reflect: true }, /*                                    */
      color: { type: "String" },
      fillParent: { type: "Boolean", attribute: "fill-parent" },
      singleSelection: { type: "Boolean", attribute: "single-selection" },
      strikeThrough: { type: "Boolean", attribute: "strike-through" },
      imgSrc: { type: "String", attribute: "img-src" },
      ocAriaLabel: { type: "String", attribute: "oc-aria-label" },
    },
  }}
/>

<script lang="ts">
  import {
    implicitSubmit,
    refireNonComposableNativeEvent,
    singleSelection as useSingleSelection,
    stopLabelClickPropagation,
  } from "../../../common/actions";
  import type { Props } from "./SelectionTileV1.types.js";

  let {
    variant = "text",
    disabled = false,
    checked = false,
    value = "on",
    name = undefined,
    fillParent = false,
    color = undefined,
    imgSrc = undefined,
    singleSelection = false,
    strikeThrough = false,
    ocAriaLabel = undefined,
    internals,
  }: Props & {
    internals: ElementInternals;
  } = $props();

  const Host = $host();

  export function resetForm() {
    /*                            */
    checked = Host.hasAttribute("checked");
  }

  $effect(() => {
    internals.setFormValue(checked ? value : null);
  });

  const onChange = (event: Event) => {
    const input = event.target as HTMLInputElement;
    if (!event.defaultPrevented) checked = input.checked;
  };

  let clientWidth = $state(0);
  let clientHeight = $state(0);
  let strikeThroughSize = $derived(`${Math.min(clientWidth, clientHeight)}px`);
</script>

<label
  class="selection-tile {`selection-tile--variant-${variant}`} "
  class:selection-tile--fill-parent={fillParent}
  class:selection-tile--checked={checked}
  class:selection-tile--disabled={disabled}
  class:selection-tile--variant-container--fill-parent={variant === "container" && fillParent}
  style:--color={color}
  style:--imgSrc={imgSrc ? `url(${imgSrc})` : undefined}
  aria-label={ocAriaLabel}
  use:stopLabelClickPropagation
  bind:clientWidth
  bind:clientHeight
  style:--strike-through-size={strikeThroughSize}
>
  {#if singleSelection}
    <input
      type="radio"
      class="selection-tile__input"
      {checked}
      {name}
      {disabled}
      use:useSingleSelection={{ Host, name, checked }}
      use:refireNonComposableNativeEvent={Host}
      use:implicitSubmit={internals}
      onchange={onChange}
      aria-label={ocAriaLabel}
    />
  {:else}
    <input
      type="checkbox"
      class="selection-tile__input"
      bind:checked
      {name}
      {disabled}
      use:refireNonComposableNativeEvent={Host}
      use:implicitSubmit={internals}
      aria-label={ocAriaLabel}
    />
  {/if}
  {#if variant === "text" || variant === "container"}
    <slot />
  {/if}

  <span class="selection-tile__overlay"></span>

  {#if strikeThrough}
    <span class="selection-tile__strikethrough"> </span>
  {/if}
</label>

<style lang="scss" global>
  @use "@otto-ec/design-tokens/component" as tokens;
  @use "@otto-ec/otto-components-utils/scss/mixins";

  :host {
    /*        */
    --color: unset;
    --imgSrc: unset;

    @include mixins.no-tap-highlight();
    display: block;
  }

  .selection-tile {
    position: relative;
    text-align: center;
  }

  .selection-tile__input {
    all: unset;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: inherit;

    & {
      @include mixins.focus-styles(inherit);
    }
  }

  @mixin selection-tile-rounded {
    padding: 0;
    width: tokens.$oc-component-selection-tile-round-size;
    height: tokens.$oc-component-selection-tile-round-size;
    border-radius: 50%;
  }

  @mixin pseudo-element() {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: inherit;
  }

  .selection-tile {
    /*     */
    position: relative;
    display: inline-block;
    padding: 12px 16px;
    box-sizing: border-box;
    cursor: pointer;

    /*    */
    border-radius: tokens.$oc-component-selection-tile-roundrect-border-radius;
    background: tokens.$oc-component-selection-tile-background-color;
    outline: tokens.$oc-component-selection-tile-border-color solid
      tokens.$oc-component-selection-tile-border-width;
    color: tokens.$oc-component-selection-tile-text-color;

    &--variant-text {
      font: tokens.$oc-component-selection-tile-text-font;
    }

    &--variant-color {
      @include selection-tile-rounded();
      background-color: var(--color);

      height: tokens.$oc-component-selection-tile-round-size;
      width: tokens.$oc-component-selection-tile-round-size;
      padding: unset;
    }

    &--variant-image {
      @include selection-tile-rounded();
      background-image: var(--imgSrc);
      background-position: center;
      background-size: 100%;

      height: tokens.$oc-component-selection-tile-round-size;
      width: tokens.$oc-component-selection-tile-round-size;
      padding: unset;
    }

    &--variant-container {
      padding: 0;
    }

    &--fill-parent {
      width: 100%;
    }

    /*         */
    @media (hover: hover) {
      &:hover:not(.selection-tile--disabled):not(.selection-tile--checked) {
        outline: tokens.$oc-component-selection-tile-border-color-hover solid
          tokens.$oc-component-selection-tile-border-width-hover;
      }
    }

    /*         */
    @media (hover: hover) {
      &:hover:not(.selection-tile--disabled):not(.selection-tile--checked)
        .selection-tile__overlay {
        @include pseudo-element();

        outline: tokens.$oc-component-selection-tile-inner-border-color-selected solid
          tokens.$oc-component-selection-tile-inner-border-width-hover;
        outline-offset: -1px;
      }
    }

    /*           */

    &:active:not(.selection-tile--checked):not(.selection-tile--disabled) {
      outline: tokens.$oc-component-selection-tile-border-color-active solid
        tokens.$oc-component-selection-tile-border-width-active;
    }

    /*         */
    &:active:not(.selection-tile--checked):not(.selection-tile--disabled) .selection-tile__overlay {
      @include pseudo-element();

      outline: tokens.$oc-component-selection-tile-inner-border-color-selected solid
        tokens.$oc-component-selection-tile-inner-border-width-active;
      outline-offset: -1px;
    }

    /*            */
    &--checked {
      outline: tokens.$oc-component-selection-tile-border-color-selected solid
        tokens.$oc-component-selection-tile-border-width-selected;

      /*         */
      @media (hover: hover) {
        &:hover:not(.selection-tile--disabled) {
          outline: tokens.$oc-component-selection-tile-border-color-selected-hover solid
            tokens.$oc-component-selection-tile-border-width-selected-hover;
        }
      }

      /*                 */
      &:active:not(.selection-tile--disabled) {
        outline: tokens.$oc-component-selection-tile-border-color-selected-active solid
          tokens.$oc-component-selection-tile-border-width-selected-active;
      }
    }
    &--checked .selection-tile__overlay {
      @include pseudo-element();
      outline: tokens.$oc-component-selection-tile-inner-border-color-selected solid
        tokens.$oc-component-selection-tile-inner-border-width-selected;
      outline-offset: -2px;

      /*               */
      @media (hover: hover) {
        &:hover .selection-tile__overlay {
          outline: tokens.$oc-component-selection-tile-inner-border-color-selected solid
            tokens.$oc-component-selection-tile-inner-border-width-selected;
          outline-offset: -1px;
        }
      }

      /*                 */
      &:active .selection-tile__overlay {
        outline: tokens.$oc-component-selection-tile-border-color-selected-active solid
          tokens.$oc-component-selection-tile-inner-border-width-selected;
        outline-offset: -1px;
      }
    }

    /*             */
    &--disabled {
      outline: none;
    }

    /*                                           */
    &--disabled .selection-tile__overlay {
      @include pseudo-element();
      cursor: default;

      background-color: tokens.$oc-component-selection-tile-disabled-overlay;
      outline: tokens.$oc-component-selection-tile-disabled-border-color solid
        tokens.$oc-component-selection-tile-disabled-border-width;
      outline-offset: 0;
    }

    /*     */
    &:before {
      content: "";
      position: absolute;

      /*                */
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      min-width: tokens.$oc-component-selection-tile-round-hitbox-size;
      min-height: tokens.$oc-component-selection-tile-round-hitbox-size;
    }

    /*            */
    &__strikethrough {
      padding: tokens.$oc-semantic-spacing-50;
      position: absolute;
      box-sizing: border-box;
      height: var(--strike-through-size);
      width: var(--strike-through-size);

      /*                       */
      inset: 0;
      margin: auto;

      &:after {
        content: "";
        position: absolute;
        border-radius: tokens.$oc-semantic-border-radius-200;
        right: tokens.$oc-semantic-spacing-50;
        top: tokens.$oc-semantic-spacing-50;

        height: calc(1.41 * (100% - 2 * tokens.$oc-semantic-spacing-50));
        background: tokens.$oc-component-selection-tile-strike-through-color;
        width: tokens.$oc-component-selection-tile-strike-through-width;
        outline: tokens.$oc-component-selection-tile-strike-through-outline-color solid
          tokens.$oc-component-selection-tile-strike-through-outline-width;

        transform-origin: top left;
        transform: rotate(45deg);
      }
    }
  }
</style>
