import { stringToDocumentFragment } from "@otto-ec/global-resources/dom";
import { executeInlineScripts } from "@otto-ec/global-resources/hardcore";
import {
  flatMap,
  pipe,
  succeed,
  fail,
  type Effect,
  mapErrorAsync,
  type Async,
  tryMapAsync,
  catchError,
  tap,
  tapError,
  map,
  fromExpression,
  trySyncImmediate,
  catchType,
  tryPromiseImmediate,
  mapIf,
  allSync,
  tapSpread,
  fromNullable,
} from "@otto-ec/assets-core-utils/nano-effect";
import { sheetScope } from "./utils";
import { getPropsFromContent, parseLegacyContent, updatePropsFromContent } from "./contentParser";
import type { OcSheetV1Props } from "../SheetV1.types.g";

const log = /*           */ sheetScope.scope("external-content");

/**
 *
 */
export class LoadError extends Error {
  /**
 *
 */
  name = "LoadError";

  /**
 *
 */
  status: number;

  /**
 *
 */
  response: Response;

  /**
 *
 *
 */
  errorContent?: string;

  constructor(message: string, response: Response, errorContent?: string | undefined) {
    const msg = response ? `${message}: ${response.status}, ${response.url}` : message;
    super(msg);

    /*                                                */
    this.status = response.status || 300;
    this.response = response;
    this.errorContent = errorContent;
  }
}

/**
 *
 *
 *
 *
 */
export function assertContentURL(url: string): Effect<string, Error> {
  return pipe(
    /*                                                    */
    trySyncImmediate(
      () => new URL(url, window.location.href),
      (cause) => new Error(`Could not parse given URL: ${url}`, { cause }),
    ),
    /*                                                                              */
    map(
      ({ hostname }) =>
        /*                                              */
        hostname === window.location.hostname ||
        /*                                                                     */
        hostname.endsWith("otto.de"),
    ),
    /*                                            */
    mapIf({
      onTrue: () => succeed<string, Error>(url),
      onFalse: () => fail(new Error(`URL hostname is not allowed in: ${url}`)),
    }),
  );
}

/**
 *
 *
 *
 */
const checkResponseError = (allowedCodes: number[]) =>
  catchError((response: Response) =>
    pipe(
      /*                                                                                  */
      !!response.headers.get("Cache-Control")?.includes("no-transform") &&
        /*                                                                                  */
        (allowedCodes.length === 0 || allowedCodes.includes(response.status)),

      /*                                                                 */
      (hasContent) => fail([response, hasContent] as const),
    ),
  );

/**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
export function fetchFromUrl(
  url: string,
  options: { allowedCodes: number[] },
  /*                                        */
  controller = new AbortController(),
): Effect<string, Error | LoadError, Async> {
  return pipe(
    /*                          */
    tryPromiseImmediate(
      () =>
        fetch(url, {
          redirect: "manual",
          headers: { Accept: "text/html" },
          signal: controller.signal,
        }),
      (e) => [e as Error, false] as const,
    ),

    /*                                              */
    flatMap((response) =>
      pipe(
        /*                                              */
        fromExpression(
          response.ok && !!response.headers.get("Content-Type")?.includes("html")
            ? succeed(response)
            : fail(response),
        ),
        /*                                                 */
        checkResponseError(options.allowedCodes),
      ),
    ),

    /*                                                                        */
    mapErrorAsync(async ([err, hasContent]) => {
      if (hasContent) {
        /*                                                   */
        return new LoadError("Error Response", err, await err.text());
      }

      /*                                                 */
      controller.abort();
      return err instanceof Error ? err : new LoadError("Bad response", err, undefined);
    }),

    /*                 */
    tryMapAsync(
      (response) => response.text(),
      (e) => new Error(`Error loading content: ${e}`),
    ),
  );
}

/*                                                                        */
export function useExternalContent(host: HTMLOcSheetV1Element) {
  const state = $state({
    /*                                                                      */
    isActive: false,
    /*                                                           */
    url: undefined as string | undefined,
    /*                                               */
    allowedCodes: [] as number[],
    /*                                                    */
    forbiddenProps: [] as string[],

    /*                                                                                  */
    validUrl: null as string | null,

    /*                                                                                */
    rawContent: null as string | null,
    /*                                                                 */
    rawError: null as Error | LoadError | null,

    /*                                                                                    */
    loadedContent: null as DocumentFragment | null,
    /*                                                                                        */
    errorContent: null as null | DocumentFragment,

    /*                                                                              */
    externalOptions: null as Partial<OcSheetV1Props> | null,
    /*                                                                 */
    externalContent: null as DocumentFragment | null,

    /*                                                                       */
    isApplied: false,
  });

  /**
 *
 *
 */
  let currentSheetDom: DocumentFragment | null = null;

  /*                                                                   */
  $effect(() => {
    if (!state.url) return;

    pipe(
      assertContentURL(state.url),
      tap((url) => {
        /*                                                         */
        state.rawContent = null;
        state.rawError = null;

        /*                                                                                */
        state.validUrl = import.meta.env.DEV && import.meta.env.STORYBOOK ? `/dev${url}` : url;

        if (currentSheetDom) {
          /*                                                                   */
          host.replaceChildren(currentSheetDom);
        }
      }),
      /*                       */
      tapError((error) => {
        if (import.meta.env.DEV) log.error("Error validating external content url", error);
        state.validUrl = null;
        state.rawError = error;
      }),
    );
  });

  /*                                                           */
  /*                                                  */
  $effect(() => {
    if (!state.isActive || !state.validUrl || state.rawContent !== null) return;

    pipe(
      fetchFromUrl(state.validUrl, state),
      tap((raw) => {
        /*                                                              */
        state.rawContent = raw;
        state.isApplied = false;
      }),
      tapError((error) => {
        /*                                      */
        state.rawError = error;
      }),
    );
  });

  /*                                                   */
  $effect(() => {
    if (!state.rawContent) return;
    /*                                              */
    state.loadedContent = stringToDocumentFragment(state.rawContent);
  });

  /*                                                           */
  $effect(() => {
    pipe(
      /*                                    */
      fromExpression(state.rawError ? fail(state.rawError) : succeed(null)),
      /*                           */
      tap((nullValue) => {
        state.errorContent = nullValue;
      }),
      /*                                                  */
      tapError((error) => log.error("Error loading external content", error)),
      /*                                 */
      catchType(LoadError, (error) => {
        if (error.errorContent) {
          /*                                      */
          state.errorContent = stringToDocumentFragment(error.errorContent);
        }
        return succeed(null);
      }),
    );
  });

  /*                                               */
  $effect(() => {
    pipe(
      /*                                   */
      fromNullable(state.loadedContent ?? state.errorContent),
      /*                               */
      flatMap((content) => allSync([getPropsFromContent(content), parseLegacyContent(content)])),
      /*                                                              */
      tapSpread((externalOptions, externalContent) => {
        state.externalOptions = externalOptions;
        state.externalContent = externalContent;
      }),
      /*                                                             */
      tapError((empty) => {
        state.externalOptions = empty;
        state.externalContent = empty;
      }),
    );
  });

  /*                                 */
  $effect(() => {
    if (!state.externalOptions) return;
    /*                         */
    updatePropsFromContent(host, state.forbiddenProps, state.externalOptions);
  });

  /*                                         */
  $effect(() => {
    if (!state.externalContent) return;

    /*                                            */
    if (import.meta.env.DEV) log.debug("Applying external content to sheet", state.externalContent);
    if (!currentSheetDom) {
      currentSheetDom = new DocumentFragment();
    }
    currentSheetDom.replaceChildren(...host.childNodes);

    /*                         */
    host.appendChild(state.externalContent);
    executeInlineScripts(host);

    /*                                                                 */
    /*                                          */
    state.isApplied = true;
  });

  return state;
}
