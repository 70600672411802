/**
 *
 *
 */
export function requestSubmit(form: HTMLFormElement | null, element: HTMLElement): void {
  if (!form || !element) {
    return;
  }

  const submitter = element.cloneNode(true) as HTMLElement;
  submitter.style.display = "none";
  form.appendChild(submitter);
  form.requestSubmit(submitter);

  /*                                                                                */
  /*                                                                                */
  /*                                                                                 */
  /*                                                                                        */
  /*        */
  setTimeout(() => {
    form.removeChild(submitter);
  }, 0);
}
