<svelte:options
  customElement={{
    tag: "oc-cinema-v1",
    shadow: "none",
    /*                                            */
    extend: window.__components.extend({ delegateFocus: true }),
    props: {
      arrowButtonPosition: { type: "String", attribute: "arrow-button-position" },
      ocAriaLabel: { type: "String", attribute: "oc-aria-label" },
      stretchItems: { type: "Boolean", attribute: "stretch-items" },
      paginationDots: { type: "Boolean", attribute: "pagination-dots" },
      itemVisibilityThreshold: { type: "Array", attribute: "item-visibility-threshold" },
      cut: { type: "String", attribute: "cut" },
      gap: { type: "String", attribute: "gap" },
      itemsPerPage: { type: "String", attribute: "items-per-page" },
    },
  }}
/>

<script lang="ts">
  import { onMount, tick } from "svelte";

  import { useEventDispatcher } from "@otto-ec/otto-components-utils/use/event-dispatcher";
  import { refireScrollEvent } from "../../../common/actions/refireScrollEvent";
  import { ArrowButtonV1 } from "../../../common/components/ArrowButton";
  import PaginationDotsV1 from "../../../common/components/PaginationDotsV1/PaginationDotsV1.svelte";

  import type { Events, Props } from "./CinemaV1.types";
  import { calcItemsPerPage } from "./math/calcItemsPerPage";
  import { calcScrollPositions } from "./math/calcScrollPositions";

  type CinemaItemHTMLElement = {
    isVisible?: boolean;
    trackingIsVisible?: boolean;
  } & HTMLElement;

  let {
    ocAriaLabel,
    arrowButtonPosition,
    resizing = "stretch-cut",
    paginationDots = false,
    itemVisibilityThreshold = undefined,
    visibleItems = [],
    cut = undefined,
    gap = undefined,
    itemsPerPage = undefined,
  }: Props = $props();

  const Host = $host();

  const dispatch = useEventDispatcher<Events>(Host);

  let stage = $state<HTMLElement>();
  let stageScrolling = false;
  let stageScrollSource: "arrows" | "pagination-dots" | "move-method" | "swipe" = "swipe";

  let prevCurrentItemIndex: number = 0;
  let currentItemIndex = 0;
  let items = $state(Array.from(Host.children) as CinemaItemHTMLElement[]);

  let stageClientWidth: number = $state(1);
  let stageScrollLeft: number = $state(0);
  let stageScrollWidth = $state(0);

  /*                                                                                                                       */
  /*                          */
  /*                                                         */
  /*                                                      */
  let update = $derived(([stageClientWidth, stageScrollLeft, items] && Date.now()) as number);

  $effect(() => {
    if (stage && update) {
      /*                                                    */
      tick().then(() => {
        stageScrollWidth = stage!.scrollWidth;
      });
    }
  });

  let itemGap = $derived(update && parseFloat(getComputedStyle(Host).getPropertyValue("--gap")));
  let itemCut = $derived(update && parseFloat(getComputedStyle(Host).getPropertyValue("--cut")));

  let hideLeftArrow = $derived(!!update && stageScrollLeft <= itemGap);
  let hideRightArrow = $derived(
    !!update && stageScrollLeft >= stageScrollWidth - stageClientWidth - itemGap,
  );

  let scrollPoints = $derived(
    stageScrollWidth ? calcScrollPositions(items, stageClientWidth, itemGap, itemCut) : [0],
  );

  let pageTransitionsPoints = $derived(
    scrollPoints.map((p, i) => ((scrollPoints[i - 1] ?? 0) + p) / 2),
  );

  let currentPage = $derived(
    Math.max(
      pageTransitionsPoints.findIndex((point) => point >= stageScrollLeft) - 1,
      /*                                                                                                                        */
      /*                                                                                                                 */
      pageTransitionsPoints.findIndex((point) => point >= stageScrollLeft) === -1
        ? scrollPoints.length - 1
        : 0,
    ),
  );

  let itemsPerPageDerived = $derived(calcItemsPerPage(stageClientWidth, itemGap, itemCut));

  /*                                                                                         */
  let stretchItems = $derived(
    resizing === "stretch" || (resizing === "stretch-cut" && itemsPerPageDerived >= items.length),
  );

  export const moveTo = (
    targetSlide: number,
    options?: { source?: "arrows" | "pagination-dots"; reducedMotion?: boolean },
  ) => {
    if (stage == undefined) {
      return;
    }

    options?.source != undefined
      ? (stageScrollSource = options.source)
      : (stageScrollSource = "move-method");

    if (options?.reducedMotion || window.matchMedia("(prefers-reduced-motion: reduce)").matches) {
      stage.style.scrollBehavior = "auto";
    } else {
      stage.style.scrollBehavior = "smooth";
    }

    stage!.scrollLeft =
      scrollPoints[Math.max(targetSlide, 0)] ?? scrollPoints[scrollPoints.length - 1];
  };

  let intersectingTimer = -1;

  /*                                           */
  const intersectingFinished = () => {
    if (currentItemIndex === prevCurrentItemIndex) return;

    stageScrolling = false;
    if (stageScrollSource !== undefined) {
      dispatch(
        "oc-stage-scroll",
        {
          isScrolling: stageScrolling,
          source: stageScrollSource,
        },
        { cancelable: false },
      );
    }

    if (itemVisibilityThreshold !== undefined && stageScrollSource !== undefined) {
      dispatch(
        "oc-item-visibility-changed",
        {
          visibleItems: {
            elements: items.filter((item) => item.trackingIsVisible),
            indices: visibleItems.map((v) => items.indexOf(v)),
          },
          scrollDirection: currentItemIndex > prevCurrentItemIndex ? "right" : "left",
          stageIsScrolling: stageScrolling,
          scrollSource: stageScrollSource,
        },
        { cancelable: false },
      );
    } else {
      if (stageScrollSource !== undefined) {
        dispatch(
          "oc-item-visibility-changed",
          {
            visibleItems: {
              elements: items.filter((item) => item.isVisible),
              indices: visibleItems.map((v) => items.indexOf(v)),
            },
            scrollDirection: currentItemIndex > prevCurrentItemIndex ? "right" : "left",
            stageIsScrolling: stageScrolling,
            scrollSource: stageScrollSource,
          },
          { cancelable: false },
        );
      }
    }

    prevCurrentItemIndex = currentItemIndex;
    stageScrollSource = "swipe";
  };

  const cinemaIntersecting = () => {
    clearTimeout(intersectingTimer);

    intersectingTimer = window.setTimeout(intersectingFinished, 500);
  };

  onMount(() => {
    stage?.addEventListener(
      "scroll",
      () => {
        /*                                          */
        stageScrollLeft = stage.scrollLeft;
        stageScrolling = true;
        cinemaIntersecting();
      },
      { passive: true },
    );

    Host.addEventListener("focusin", (event) => {
      const interactiveElement = event.composedPath()[0] as HTMLElement | null;
      const item = event.target as CinemaItemHTMLElement | null;

      if (
        item instanceof HTMLElement &&
        interactiveElement?.matches(":focus-visible") &&
        !item.isVisible
      ) {
        item.scrollIntoView({ inline: "center", block: "nearest" });
      }
    });

    /*                                                                  */
    let slideVisibilityIntersectionObserver: IntersectionObserver;
    if (itemVisibilityThreshold) {
      slideVisibilityIntersectionObserver = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            const target = entry.target as CinemaItemHTMLElement;
            target.trackingIsVisible = entry.isIntersecting;
          });

          visibleItems = items.filter((item) => item.trackingIsVisible);

          if (currentItemIndex !== prevCurrentItemIndex) {
            dispatch(
              "oc-stage-scroll",
              {
                isScrolling: stageScrolling,
                source: stageScrollSource,
              },
              { cancelable: false },
            );

            if (visibleItems) {
              dispatch(
                "oc-item-visibility-changed",
                {
                  visibleItems: {
                    elements: visibleItems,
                    indices: visibleItems.map((v) => items.indexOf(v)),
                  },
                  scrollDirection: currentItemIndex > prevCurrentItemIndex ? "right" : "left",
                  stageIsScrolling: stageScrolling,
                  scrollSource: stageScrollSource,
                },
                { cancelable: false },
              );
            }
          }
        },
        { root: stage, threshold: itemVisibilityThreshold },
      );

      items.forEach((i) => slideVisibilityIntersectionObserver.observe(i));
    }

    let lastIntersectingItem: CinemaItemHTMLElement | null = null;

    /*                                                            */
    const intersectionObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const target = entry.target as CinemaItemHTMLElement;
          target.isVisible = entry.isIntersecting;
          if (entry.isIntersecting) {
            lastIntersectingItem = target;
            stageScrolling = true;
          }
        });

        currentItemIndex = items.findIndex((i) => i.isVisible);
        if (currentItemIndex === -1) {
          /*                                                            */
          currentItemIndex = items.findIndex((i) => i === lastIntersectingItem);
        }

        if (!itemVisibilityThreshold) {
          visibleItems = items.filter((item) => item.isVisible);

          if (currentItemIndex !== prevCurrentItemIndex) {
            dispatch(
              "oc-stage-scroll",
              {
                isScrolling: stageScrolling,
                source: stageScrollSource,
              },
              { cancelable: false },
            );

            if (visibleItems) {
              dispatch(
                "oc-item-visibility-changed",
                {
                  visibleItems: {
                    elements: visibleItems,
                    indices: visibleItems.map((v) => items.indexOf(v)),
                  },
                  scrollDirection: currentItemIndex > prevCurrentItemIndex ? "right" : "left",
                  stageIsScrolling: stageScrolling,
                  scrollSource: stageScrollSource,
                },
                { cancelable: false },
              );
            }
          }
        }
      },
      { root: stage, threshold: [0.999, 1] },
    );

    items.forEach((i) => intersectionObserver.observe(i));

    const mutationObserver = new MutationObserver((mutations) => {
      mutations.forEach(({ removedNodes, addedNodes }) => {
        addedNodes.forEach((node) => {
          if (node instanceof HTMLElement) {
            intersectionObserver.observe(node);
            slideVisibilityIntersectionObserver?.observe(node);
          }
        });
        removedNodes.forEach((node) => {
          if (node instanceof HTMLElement) {
            intersectionObserver.unobserve(node);
            slideVisibilityIntersectionObserver?.unobserve(node);
          }
        });
        items = Array.from(Host.children) as CinemaItemHTMLElement[];
      });
    });

    mutationObserver.observe(Host, {
      childList: true,
    });

    return () => {
      intersectionObserver.disconnect();
      mutationObserver.disconnect();
      slideVisibilityIntersectionObserver?.disconnect();
    };
  });
</script>

<div
  class="cinema"
  style:--arrow-button-position={arrowButtonPosition}
  style:--cut={cut}
  style:--gap={gap}
  style:--items-per-page={itemsPerPage}
  style:--internal-items-per-page={itemsPerPageDerived}
>
  <section
    class={`cinema__stage cinema__stage--${resizing}`}
    class:cinema__stage--stretch={stretchItems}
    class:cinema__stage--cut={resizing === "stretch-cut" || resizing === "cut"}
    bind:this={stage}
    bind:clientWidth={stageClientWidth}
    use:refireScrollEvent={Host}
    aria-label={ocAriaLabel}
    data-oc-floating-focus-v1-target
  >
    <slot />
  </section>
  <ArrowButtonV1
    hidden={hideLeftArrow}
    direction="left"
    onclick={() => moveTo(currentPage - 1, { source: "arrows" })}
  />
  <ArrowButtonV1
    hidden={hideRightArrow}
    direction="right"
    onclick={() => moveTo(currentPage + 1, { source: "arrows" })}
  />
</div>

{#if paginationDots}
  <PaginationDotsV1 amountDots={scrollPoints.length} selectedDot={currentPage} {moveTo} />
{/if}

<style lang="scss" global>
  @use "@otto-ec/otto-components-utils/scss/mixins";
  @use "@otto-ec/design-tokens/component" as tokens;
  @use "math/calcItemBase" as *;
  @use "../../floating-focus/v1/FloatingFocusV1.global";

  :host {
    /*        */
    --gap: #{tokens.$oc-component-cinema-stage-gap-x};
    --cut: #{tokens.$oc-component-cinema-cut-item-visible-percentage};
    --arrow-button-position: 50%;
    --items-per-page: unset;

    @include mixins.no-tap-highlight();
    display: block;
    max-height: 50rem;
  }

  .cinema {
    position: relative;

    &__stage {
      display: flex;
      gap: var(--gap);
      overflow-x: auto;
      overflow-y: hidden;
      scrollbar-width: none; /*     */
      scroll-behavior: smooth;

      /*                                  */
      padding: 4px 0;
      margin: -4px 0;

      &::-webkit-scrollbar {
        display: none; /*                */
      }

      /*                                            */
      @media (prefers-reduced-motion) {
        scroll-behavior: auto;
      }

      &:before,
      &:after {
        content: "";
        flex: 0 0 0;
      }

      ::slotted(*) {
        flex: 0 0 auto;
        max-width: 100%;
      }

      &--cut {
        ::slotted(*) {
          flex: 0 0
            calcItemBase(
              var(--items-per-page, var(--internal-items-per-page)),
              var(--gap),
              var(--cut)
            );
        }
      }

      &--stretch {
        ::slotted(*) {
          flex: 1 0 auto;
        }
      }
    }
  }
</style>
