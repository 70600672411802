import type { Action } from "svelte/action";

/**
 *
 *
 *
 *
 *
 *
 */
export const refireScrollEvent: Action<HTMLElement, HTMLElement> = (node, host) => {
  const handleEvent = (event: Event): void => {
    /*                                                        */
    host.dispatchEvent(new event.constructor(event.type, event));
  };

  node.addEventListener("scroll", handleEvent, { passive: true, capture: true });
  return {
    destroy: () => node.removeEventListener("scroll", handleEvent),
  };
};
