/**
 *
 */
export function calcItemsPerPage(
  stageClientWidth: number,
  cinemaStageGap: number,
  cinemaStageCut: number,
): number {
  const maxItemWidth = 312;
  return Math.max(
    1,
    Math.ceil(
      (stageClientWidth - cinemaStageGap - maxItemWidth * cinemaStageCut) /
        (cinemaStageGap + maxItemWidth),
    ),
  );
}
