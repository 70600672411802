<svelte:options
  customElement={{
    tag: "oc-promo-card-v1",
    /*                                            */
    extend: window.__components.extend(),
    props: {
      asButton: { type: "Boolean", attribute: "as-button" },
      base64Href: { type: "String", attribute: "base64-href" },
      footerHeadline: { type: "String", attribute: "footer-headline" },
      footerText: { type: "String", attribute: "footer-text" },
      href: { type: "String" },
      mainHeadline: { type: "String", attribute: "main-headline" },
      ocAriaVisibleElements: { type: "Array", attribute: "oc-aria-visible-elements" },
      overline: { type: "String" },
      rel: { type: "String" },
      target: { type: "String" },
    },
  }}
/>

<script lang="ts">
  import type { OcAriaVisibleElement, Props } from "./PromoCardV1.types.js";
  import { useSlots } from "../../../common/utils/useSlots.svelte";
  import BasicCardV2 from "../../../common/components/Card/BasicCardV2.svelte";

  let {
    mainHeadline = undefined,
    overline = undefined,
    footerHeadline = undefined,
    footerText = undefined,
    asButton = false,
    href = undefined,
    base64Href = undefined,
    ocAriaVisibleElements = [
      "overline",
      "mainHeadline",
      "logo",
      "tags",
      "mainContent",
      "footerHeadline",
      "footerText",
    ],
    rel = undefined,
    target = undefined,
  }: Props = $props();

  const Host = $host<HTMLElement>();

  const slots = useSlots(Host);

  const hasHeader = $derived(overline || mainHeadline || slots.logo);
  const hasFooter = $derived(footerHeadline || footerText);

  const allAriaVisibleElements = $derived(ocAriaVisibleElements!);

  const isAriaHidden = (element: OcAriaVisibleElement) => {
    return !allAriaVisibleElements.includes(element);
  };
</script>

<BasicCardV2 {Host} {asButton} bind:href bind:base64Href class="promo-card" {rel} {target}>
  {#snippet children(onslotchange)}
    <slot name="primary-action" {onslotchange} />
    {#if hasHeader}
      <header class="promo-card__header">
        <div class="promo-card__header-container">
          {#if overline}
            <span
              class="promo-card__header-container-overline"
              aria-hidden={isAriaHidden("overline")}>{overline}</span
            >
          {/if}
          {#if mainHeadline}
            <span
              class="promo-card__header-container-main-headline"
              aria-hidden={isAriaHidden("mainHeadline")}>{mainHeadline}</span
            >
          {/if}
        </div>
        {#if slots.logo}
          <slot name="logo" aria-hidden={isAriaHidden("logo")} />
        {/if}
      </header>
    {/if}

    <div class="promo-card__body">
      <slot aria-hidden={isAriaHidden("mainContent")} />
      {#if slots.tags}
        <div class="promo-card__tag-container">
          <slot name="tags" aria-hidden={isAriaHidden("tags")} />
        </div>
      {/if}
    </div>

    {#if hasFooter}
      <footer class="promo-card__footer">
        {#if footerHeadline}
          <span class="promo-card__footer-headline" aria-hidden={isAriaHidden("footerHeadline")}
            >{footerHeadline}</span
          >
        {/if}
        {#if footerText}
          <span class="promo-card__footer-text" aria-hidden={isAriaHidden("footerText")}
            >{footerText}</span
          >
        {/if}
      </footer>
    {/if}
  {/snippet}
</BasicCardV2>

<style lang="scss" global>
  @use "@otto-ec/design-tokens/component" as tokens;
  @use "@otto-ec/otto-components-utils/scss/mixins";

  :host {
    @include mixins.no-tap-highlight();
    display: block;
    position: relative;
    container-type: inline-size;
  }

  .promo-card__header {
    display: flex;
    flex-direction: row;
    gap: tokens.$oc-component-promo-card-gap-logo;
    padding: tokens.$oc-component-promo-card-spacing-x tokens.$oc-component-promo-card-spacing-y;
    align-items: center;

    height: calc(tokens.$oc-component-promo-card-s-m-header-height - 2 * tokens.$oc-component-promo-card-spacing-y);

    @include mixins.breakpoint-from-l(false, $containerFirst: true) {
      height: calc(tokens.$oc-component-promo-card-l-xl-header-height - 2 * tokens.$oc-component-promo-card-spacing-y);
    }

    &-container {
      display: flex;
      flex-direction: column;
      justify-content: center;

      /*                           */
      flex: 1;

      &,
      & > * {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &-overline {
        font: tokens.$oc-component-promo-card-overline-font;
      }

      &-main-headline {
        font: tokens.$oc-component-promo-card-s-m-first-headline-font;
        @include mixins.breakpoint-from-l(false, $containerFirst: true) {
          font: tokens.$oc-component-promo-card-l-xl-first-headline-font;
        }
      }
    }
  }

  /*               */
  .promo-card__body {
    position: relative;
    overflow: hidden;
  }

  .promo-card__tag-container {
    bottom: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 8px;
    padding: 16px;
    position: absolute;
  }

  .promo-card__footer {
    display: flex;
    flex-direction: column;
    min-height: 24px;
    padding: 16px;

    &-headline {
      /*                         */
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      display: -webkit-box;
      overflow: hidden;

      font: tokens.$oc-component-promo-card-s-m-second-headline-font;
      @include mixins.breakpoint-from-l(false, $containerFirst: true) {
        font: tokens.$oc-component-promo-card-l-xl-second-headline-font;
      }
    }

    &-text {
      font: tokens.$oc-component-promo-card-text-font;
    }
  }
</style>
