import { stateSyntax } from "./canIUse.js";

type HTMLElementWithInternals = HTMLElement & {
  internals: ElementInternals & { states: Set<string> };
};

/**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
export const exposeStatesForCSS: (host: HTMLElement, states: Record<string, boolean>) => void =
  stateSyntax
    ? (host, states): void =>
        /*                                         */
        Object.entries(states).forEach(([key, value]) =>
          value
            ? (host as HTMLElementWithInternals).internals.states.add(key)
            : (host as HTMLElementWithInternals).internals.states.delete(key),
        )
    : (host, states): void =>
        /*                                                    */
        Object.entries(states).forEach(([key, value]) =>
          value ? host.setAttribute(`state--${key}`, "") : host.removeAttribute(`state--${key}`),
        );
