<svelte:options
  customElement={{
    tag: "oc-focused-dialog-v1",
    /*                                            */
    extend: window.__components.extend(),
    props: {
      inApp: { type: "Boolean", reflect: true },
      headline: { type: "String", reflect: true },
      closeUrl: { type: "String", attribute: "close-url", reflect: true },
      prevUrl: { type: "String", attribute: "prev-url", reflect: true },
    },
  }}
/>

<script lang="ts">
  import { onMount } from "svelte";
  import { useEventDispatcher } from "@otto-ec/otto-components-utils/use/event-dispatcher";
  import { eventQBus } from "@otto-ec/global-resources/event-q-bus";
  import type { Props, Events } from "./FocusedDialogV1.types.js";
  import { getParamValue, isSafeUrl, closeParam, prefix } from "./FocusedDialogV1.utils";
  import { useSlots } from "../../../common/utils/useSlots.svelte";

  const base64HrefAttribute = "base64Href";

  const prevQuery = `[slot='actions'] [data-${prefix}\\.prev-url]`;
  const closeUrlQuery = `[slot='actions'] [data-${prefix}\\.use-close-url][href]`;

  let { closeUrl, prevUrl, headline = "", inApp = false }: Props = $props();

  closeUrl =
    getParamValue(`${prefix}.close-url`, window.location.search) ||
    /*                                                                                     */
    getParamValue("focussedDialogCloseUrl", window.location.search) ||
    closeUrl;
  prevUrl =
    getParamValue(`${prefix}.prev-url`, window.location.search) ||
    /*                                                                                    */
    getParamValue("focussedDialogPrevUrl", window.location.search) ||
    prevUrl;

  const Host = $host();
  const slots = useSlots(Host);
  const dispatch = useEventDispatcher<Events>(Host);

  onMount(async () => {
    /*                              */
    /*                                                                                                       */
    await eventQBus.once("apps.global.assets-loaded");
    /*                                                         */
    /*               */
    if (window.o_apps.runningInApp()) {
      inApp = true;
    }
  });

  function handleClose(): void {
    dispatch("oc-close");
  }

  const { host: hostName, protocol } = window.location;

  /*                                                         */
  const stuckState = $state({ title: false, actions: false });
  /*                                                                     */
  const stuckElementStateMap = new WeakMap<Element, keyof typeof stuckState>();

  const observer = new IntersectionObserver(
    ([{ target, intersectionRatio }]) => {
      const stateKey = stuckElementStateMap.get(target)!;
      stuckState[stateKey] = intersectionRatio < 1;
    },
    { threshold: [1] },
  );

  onMount(() => {
    /*                                                        */

    if (closeUrl && isSafeUrl(closeUrl, hostName, protocol)) {
      const closeUrlElements = Array.from(
        Host.querySelectorAll(closeUrlQuery) as NodeListOf<
          HTMLOcButtonV1Element | HTMLAnchorElement
        >,
      );

      closeUrlElements.forEach((el) => {
        if (el.hasAttribute(base64HrefAttribute)) {
          const newCloseUrl = new URL(
            window.atob(el.getAttribute(base64HrefAttribute) || ""),
            window.location.href,
          );
          newCloseUrl.searchParams.set(closeParam, closeUrl as string);
          el.setAttribute(base64HrefAttribute, window.btoa(newCloseUrl.href));
        } else {
          const { href } = el;

          const newCloseUrl = new URL(href as string, window.location.href);
          newCloseUrl.searchParams.set(closeParam, closeUrl as string);

          el.setAttribute("href", newCloseUrl.href);
        }
      });
    }

    if (prevUrl && isSafeUrl(prevUrl, hostName, protocol)) {
      const prevElements = Array.from(
        Host.querySelectorAll(prevQuery) as NodeListOf<HTMLOcButtonV1Element | HTMLAnchorElement>,
      );

      prevElements.forEach((el) => {
        if (el.hasAttribute(base64HrefAttribute)) {
          /*                                     */
          el.setAttribute(base64HrefAttribute, window.btoa(prevUrl as string));
        } else {
          el.href = prevUrl;
        }
      });
    }

    /**
 *
 *
 */
    (["title", "actions"] as const)
      .map((k) => [k, Host.shadowRoot!.querySelector(`.${k}`)] as const)
      .forEach(([k, e]) => {
        if (!e) return; /*                             */
        /*                                                                                */

        stuckElementStateMap.set(e, k);
        /*                       */
        observer.observe(e);
      });

    /*                  */
    return () => {
      observer.disconnect();
    };
  });
</script>

<div class="focused-dialog" class:focused-dialog--inApp={inApp}>
  <div class="title" class:title--stuck={stuckState.title}>
    <div class="title__prev">
      {#if prevUrl && isSafeUrl(prevUrl, hostName, protocol)}
        <oc-icon-button-v2 ocAriaLabel="zurück" href={prevUrl} icon="arrow-left"
        ></oc-icon-button-v2>
      {/if}
    </div>
    <slot name="headline">
      <h1 class="title__headline">{headline}</h1>
    </slot>
    <div class="title__close">
      {#if closeUrl && isSafeUrl(closeUrl, hostName, protocol)}
        <!-- svelte-ignore a11y_no_static_element_interactions -->
        <!-- svelte-ignore a11y_click_events_have_key_events -->
        <oc-icon-button-v2
          icon="close"
          onclick={handleClose}
          ocAriaLabel="schließen"
          href={closeUrl}
        ></oc-icon-button-v2>
      {/if}
    </div>
  </div>

  <section class="content-area">
    <slot />
  </section>
  {#if slots.actions}
    <div class="actions" class:actions--stuck={stuckState.actions}>
      <slot name="actions" />
    </div>
  {/if}
</div>

<style lang="scss" global>
  @use "@otto-ec/design-tokens/component" as tokens;
  @use "@otto-ec/otto-components-utils/scss/mixins" as mixins;

  :host {
    --content-background-color: unset;
    --content-padding: unset;
    --title-position: unset;
    --component-background-color: unset;
  }

  .focused-dialog {
    background-color: var(
      --component-background-color,
      tokens.$oc-component-focused-dialog-background-color
    );

    height: 100%;

    .title {
      display: flex;
      position: static;
      z-index: 1; /*                                                                               */
      top: -2px;
      justify-content: space-between;
      background-color: tokens.$oc-component-focused-dialog-header-background-color;
      padding: 24px 16px 16px;

      &__prev,
      &__close {
        /**
 *
 *
 */
        @include mixins.breakpoint-from-m($to: false, $containerFirst: false) {
          width: tokens.$oc-base-dimension-relative-32; /*                           */
        }
      }

      &__headline {
        text-align: left;
        flex-grow: 1;
        margin: 0;
        color: tokens.$oc-component-focused-dialog-header-title-color;
        word-break: break-word;
        font: tokens.$oc-component-focused-dialog-header-title-font;
        @include mixins.breakpoint-from-m($to: false, $containerFirst: false) {
          text-align: center;
        }
      }
    }

    /*                                                                                              */
    &:not(.focused-dialog--inApp) {
      .title {
        position: sticky;

        &--stuck {
          border-bottom: tokens.$oc-component-focused-dialog-header-conditional-border-bottom-width
            solid tokens.$oc-component-focused-dialog-header-conditional-border-bottom-color;
        }
      }
    }

    .content-area {
      padding: var(--content-padding, 16px);
      background-color: var(
        --content-background-color,
        tokens.$oc-component-focused-dialog-content-background-color
      );
    }

    .actions {
      background-color: tokens.$oc-component-focused-dialog-action-bar-background-color;
      padding: tokens.$oc-component-focused-dialog-action-bar-spacing-top
        tokens.$oc-component-focused-dialog-action-bar-spacing-x
        tokens.$oc-component-focused-dialog-action-bar-spacing-bottom
        tokens.$oc-component-focused-dialog-action-bar-spacing-x;
      bottom: -2px;
      position: sticky;

      &--stuck {
        border-top: tokens.$oc-component-focused-dialog-action-bar-conditional-border-top-width
          solid tokens.$oc-component-focused-dialog-action-bar-conditional-border-top-color;
      }
    }
  }
</style>
