/**
 *
 *
 *
 *
 *
 *
 */
export function calcScrollPositions(
  items: HTMLElement[],
  containerWidth: number,
  gap: number,
  cut: number,
): number[] {
  const result: number[] = [0];
  let offset = 0;
  let endOfViewPos = containerWidth;
  let previousCutWidth = 0;

  items.forEach((item) => {
    const itemWidth = item.getBoundingClientRect().width;
    const scrollPosition = offset - previousCutWidth;

    offset += gap + itemWidth;

    /*                                                                                */
    if (offset > endOfViewPos) {
      /*                                                                   */
      if (scrollPosition > 0) result.push(scrollPosition);
      endOfViewPos = scrollPosition + containerWidth;
    }

    /*                                                */
    previousCutWidth = Math.min(itemWidth, containerWidth) * cut * 0.5;
  });

  return result;
}
