import type { Action } from "svelte/action";
import { tick } from "svelte";

const events = [
  "change",
  /*                                              */
  "input",
];

/**
 *
 *
 *
 *
 *
 */
export const refireNonComposableNativeEvent: Action<HTMLElement, HTMLElement> = (node, host) => {
  const handleEvent = (event: Event): void => {
    if (event.composed) return;
    /*                                                        */
    const canceled = host.dispatchEvent(new event.constructor(event.type, event));
    if (canceled) event.preventDefault();
  };

  /*                                                                                     */
  tick().then(() => events.forEach((event) => node.addEventListener(event, handleEvent)));
  return {
    destroy: () => events.forEach((event) => node.removeEventListener(event, handleEvent)),
  };
};
