export interface FloaterDimensions {
  left: string;
  top: string;
  width: string;
  height: string;
}

const getFloaterPosition = (target: HTMLElement): FloaterDimensions => {
  const { outlineOffset } = getComputedStyle(target);
  const padding = parseFloat(outlineOffset || "0px");
  const { width, height, left, top } = target.getBoundingClientRect();
  return {
    left: `${window.scrollX + left + width / 2}px`,
    top: `${window.scrollY + top + height / 2}px`,
    width: `${width + padding * 2}px`,
    height: `${height + padding * 2}px`,
  };
};

export const repositionElement = (
  floater: HTMLElement,
  target: HTMLElement,
  borderRadius: string,
): void => {
  const styles = getFloaterPosition(target);
  Object.assign(floater.style, styles, { borderRadius });
};
