/*                                     */
import { on } from "svelte/events";
import { derived, writable } from "svelte/store";
import type { Action } from "svelte/action";
import { CLOSE_TYPES, CLOSED_WITH_DRAG } from "./events.svelte";
import { close } from "./sheetHistory";

const CLOSING_DISTANCE = 100;

/**
 *
 *
 *
 *
 *
 *
 */
export function computeDragStyle(isDragging: boolean, dragDistance: number): string {
  if (!isDragging) {
    return "";
  }

  const dragOffsetY = dragDistance > 0 ? `${dragDistance}px` : "0";
  return `transform:translateY(${dragOffsetY})`;
}

/**
 *
 *
 *
 *
 *
 *
 *
 */
export function computeContentOverflow(
  contentElement: HTMLElement | undefined | null,
  contentHeight: number,
): boolean {
  return !!contentElement && contentElement.scrollHeight !== contentHeight;
}

/*                       */
export function useSheetTouchScroll() {
  let touchStartY = 0; /*                                         */
  const hasContentOverflow = writable(false);

  /*                                                          */
  let $isDragging = false;
  const isDragging = writable(false);
  isDragging.subscribe((dragging) => {
    $isDragging = dragging;
  });

  /*                                                             */
  let $dragDistance = 0;
  const dragDistance = writable(0);
  dragDistance.subscribe((distance) => {
    $dragDistance = distance;
  });

  const sheetDragStyle = derived([isDragging, dragDistance], ([dragging, distance]) =>
    computeDragStyle(dragging, distance),
  );

  /*                                 */
  const contentHeight = writable(0);
  const isScrolling = writable(false);
  const isScrollEnd = writable(false);

  /**
 *
 */
  const contentElementWatcher: Action = (contentElement) => {
    function onScroll(): void {
      const scrollY = contentElement.scrollTop;
      isScrolling.set(scrollY > 0);
      isScrollEnd.set(
        contentElement.offsetHeight + contentElement.scrollTop >= contentElement.scrollHeight - 1,
      );
    }

    const chUnsub = contentHeight.subscribe((h) => {
      hasContentOverflow.set(computeContentOverflow(contentElement, h));
    });

    contentElement.addEventListener("scroll", onScroll);
    onScroll();

    return {
      destroy() {
        contentElement.removeEventListener("scroll", onScroll);
        chUnsub();
      },
    };
  };

  function onTouchEnd(): void {
    if ($isDragging && $dragDistance > CLOSING_DISTANCE) {
      close(CLOSE_TYPES[CLOSED_WITH_DRAG]);
    }

    dragDistance.set(0);
    isDragging.set(false);
    touchStartY = 0;
  }

  const touchHeaderEvents: Action = (node) => {
    const unsub = [
      on(node, "touchstart", function onTouchStartHeader(event: TouchEvent) {
        isDragging.set(true);
        touchStartY = event.touches[0].clientY;
      }),

      on(
        node,
        "touchmove",
        function onTouchMoveHeader(event: TouchEvent) {
          if (!$isDragging) {
            return;
          }

          dragDistance.set(event.touches[0].clientY - touchStartY);

          if (event.cancelable) {
            /*                                                     */
            event.preventDefault();
          }
        },

        /*                                                                     */
        { passive: false },
      ),
      on(node, "touchend", onTouchEnd),
    ];

    return {
      destroy: () => unsub.forEach((u) => u()),
    };
  };

  const touchContentEvents: Action = (node) => {
    const unsub = [
      on(node, "touchstart", function onTouchStartContent(event: TouchEvent) {
        if (!isScrolling) {
          touchStartY = event.touches[0].clientY;
        }
      }),
      on(node, "touchmove", function onTouchMoveContent(event: TouchEvent) {
        event.stopPropagation();

        if (touchStartY <= 0) {
          return;
        }

        dragDistance.set(event.touches[0].clientY - touchStartY);
        /*                                                                                 */
        /*                                                               */
        isDragging.set(!isScrolling && $dragDistance > 2);

        if ($isDragging && event.cancelable) {
          /*                                                     */
          event.preventDefault();
        }
      }),
      on(node, "touchend", onTouchEnd),
    ];

    return {
      destroy: () => unsub.forEach((u) => u()),
    };
  };

  return {
    /**
 *
 */
    contentHeight,

    /**
 *
 */
    isDragging,

    /**
 *
 */
    isScrolling,

    /**
 *
 */
    isScrollEnd,

    /**
 *
 */
    sheetDragStyle,

    /**
 *
 */
    hasContentOverflow,

    /**
 *
 *
 */
    contentElementWatcher,
    touchHeaderEvents,
    touchContentEvents,
  };
}
